import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import {
  OpenWidgetCommand,
  TeiaViewer,
  ToolbarItem,
  useViewerContext
} from '@stereograph/teiaviewer';

export const ChatBotToolbarItem = (): JSX.Element => {
  const { viewer } = useViewerContext<TeiaViewer>();

  const onClick = () => {
    const cmd = new OpenWidgetCommand(viewer, 'ChatBotWidget');
    cmd.execute();
  };

  return (
    <ToolbarItem
      icon={{ heroIcon: ChatBubbleLeftIcon }}
      label="Start a conversation with Elliot (alpha)"
      onClick={onClick}
    />
  );
};
