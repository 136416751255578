import AnomalyListIcon from '@resources/icons/AnomalyListIcon.svg?react';
import AnomalyWidgetIcon from '@resources/icons/AnomalyWidgetIcon.svg?react';
import CreateAnomalyIcon from '@resources/icons/CreateAnomalyIcon.svg?react';
import {
  OpenWidgetCommand,
  TeiaViewer,
  ToolbarItem,
  ToolbarMenu,
  useTeiaSource,
  useViewerContext
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { UploadAnomalyToolbarItem } from './components/UploadAnomalyToolbarItem';

export const ShowTeiaAnomalyMenu = () => {
  const { t } = usePluginTranslation();
  const { viewer } = useViewerContext<TeiaViewer>();
  const source = useTeiaSource();

  if (!source) {
    return null;
  }

  const openCreateAnomalyWidget = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaCreateAnomaly', {
      projectId: source.projectId
    });
    command.execute();
  };

  const openAnomalyResumeWidget = () => {
    const command = new OpenWidgetCommand(viewer, 'TeiaAnomalyResume', {
      projectId: source.projectId
    });
    command.execute();
  };

  return (
    <ToolbarMenu icon={{ svgIcon: AnomalyWidgetIcon }} label={t('toolbar.anomalies.tooltip')}>
      <ToolbarItem
        hidden={source === null}
        icon={{ svgIcon: CreateAnomalyIcon }}
        label={t('toolbar.anomalies.create_anomaly.tooltip')}
        onClick={openCreateAnomalyWidget}
      />
      <ToolbarItem
        hidden={source === null}
        icon={{ svgIcon: AnomalyListIcon }}
        label={t('toolbar.anomalies.anomaly_resume.tooltip')}
        onClick={openAnomalyResumeWidget}
      />
      <UploadAnomalyToolbarItem source={source} />
    </ToolbarMenu>
  );
};
