import { AnomalyHistoryProperty } from '@api/interfaces/Anomaly';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Icon, Text } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { TeiaAnomalyHistoryItemBase } from './TeiaAnomalyHistoryItemBase';

interface TeiaAnomalyHistoryItemUpdatedProps {
  property: AnomalyHistoryProperty,
}

export const TeiaAnomalyHistoryItemUpdated = (props: TeiaAnomalyHistoryItemUpdatedProps) => {
  const { property } = props;
  const { t } = usePluginTranslation();

  return (
    <TeiaAnomalyHistoryItemBase
      chipColor="warning"
      chipLabel={t('anomaly_plugin.widget.history.updated.label')}
    >
      <Text bold>{property.propertyName}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text color="grey">{property.oldValue}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text>{property.newValue}</Text>
    </TeiaAnomalyHistoryItemBase>
  );
};

