import {
  ArrowUturnLeftIcon,
  BackspaceIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';
import { IconButton, Stack, Textarea, Tooltip } from '@stereograph/teia-system-design';
import React from 'react';

interface AddPrePromptStackProps {
  isLoading: boolean;
  prePromptToAdd: string;
  setPrePromptToAdd: React.Dispatch<React.SetStateAction<string>>;
  addPrePrompt: () => void;
}

export class AddPrePromptStack extends React.Component<AddPrePromptStackProps> {
  constructor(props: AddPrePromptStackProps) {
    super(props);
  }

  override render() {
    const { isLoading, prePromptToAdd, setPrePromptToAdd, addPrePrompt } = this.props;

    return (
      <Stack direction={'row'}>
        <Textarea
          value={prePromptToAdd}
          rows={5}
          label={'Add Pre-Prompt!'}
          onChange={(event) => setPrePromptToAdd(event.target.value)}
          disabled={isLoading}
          sx={{ flexGrow: 1 }}
        />
        <Stack direction={'column'}>
          <Tooltip title="Confirm Pre-Prompt">
            <IconButton
              aria-label="Confirm Pre-Prompt"
              iconOnly
              sx={{ width: 40, height: 40 }}
              disabled={isLoading || prePromptToAdd.trim() === ''}
              onClick={() => addPrePrompt()}
            >
              <ClipboardDocumentCheckIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Go back to original Pre-Prompt">
            <IconButton
              aria-label="Go back to original Pre-Prompt"
              iconOnly
              sx={{ width: 40, height: 40 }}
              disabled={true}
            >
              <ArrowUturnLeftIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Pre-Prompt">
            <IconButton
              aria-label="Delete Pre-Prompt"
              iconOnly
              sx={{ width: 40, height: 40 }}
              disabled={true}
            >
              <BackspaceIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  }
}
