import { ApiClient } from '@stereograph/teia-system-design/apis';

export interface PrePrompt {
  prompt: string;
  promptId: number;
}

export const TiaPrePromptApi = (client: ApiClient) => ({
  getAllPrePrompts() {
    const url = client.URL('preprompts');

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Array<PrePrompt>>(request);
  },

  deletePrePrompt(id: number) {
    const url = client.URL(`preprompts/${id}`);

    const request = new Request(url, {
      method: 'DELETE'
    });

    return client.sendRequest<void>(request);
  },

  updatePrePrompt(id: number, text: string) {
    const url = client.URL(`preprompts/${id}`);
    const data = JSON.stringify({ newPrompt: text });

    const request = new Request(url, {
      method: 'POST',
      body: data
    });

    return client.sendRequest<PrePrompt>(request);
  },

  addPrePrompt(text: string) {
    const url = client.URL('preprompts');
    const data = JSON.stringify({ newPrompt: text });

    const request = new Request(url, {
      method: 'POST',
      body: data
    });

    return client.sendRequest<PrePrompt>(request);
  }
});
