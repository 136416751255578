import { ApiClient } from '@stereograph/teia-system-design/apis';
import {
  NumberController,
  PluginBuilder,
  StringController,
  TeiaViewer
} from '@stereograph/teiaviewer';
import { ChatBotToolbarItem, ChatBotWidget } from './components';

declare global {
  interface ViewerPluginRegistry {
    chatbot: ChatBotPlugin;
  }
}

const Settings = () => ({
  tiaApiUrl: new StringController(''),
  qtoPreviewSize: new NumberController(5, { min: 1, max: 10 })
});

const PluginBaseClass = PluginBuilder.NewPlugin('ChatBotPlugin', Settings);

export class ChatBotPlugin extends PluginBaseClass<TeiaViewer> {
  declare elliotAPIClient: ApiClient;

  static override readonly ToolbarItems = {
    ChatBotToolbarItem
  };

  override onRegister(): void {
    this.elliotAPIClient = new ApiClient(
      this.settings.tiaApiUrl.value,
      this.viewer.settings.twinApi.token.value
    );
    this._setupWidgets();
    this.viewer.settings.twinApi.token.onChange.connect(this._onTokenChange);
  }

  override onUnregister(): void {
    this.viewer.settings.twinApi.token.onChange.disconnect(this._onTokenChange);
  }

  private readonly _onTokenChange = () => {
    this.elliotAPIClient.token = this.viewer.settings.twinApi.token.value;
  };

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('chatbot', () => {
      return new ChatBotWidget(this.viewer, {
        x: 5,
        y: 5,
        width: 450,
        height: 550,
        minHeight: 200,
        minWidth: 300
      });
    });
  }
}
