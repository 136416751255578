import { OrderDirection } from '@api/interfaces/Anomaly';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { Icon, IconButton, Stack, Text, Tooltip } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { Dispatch, SetStateAction } from 'react';

interface SortHistoriesButtonProps {
  orderDirection: OrderDirection;
  setOrderDirection: Dispatch<SetStateAction<OrderDirection>>
}

export const SortHistoriesButton = (props: SortHistoriesButtonProps) => {
  const { orderDirection, setOrderDirection } = props;
  const { t } = usePluginTranslation();

  const isOrderDirectionAsc = orderDirection === 'asc';

  const switchOrderDirection = () => {
    setOrderDirection(isOrderDirectionAsc ? 'desc' : 'asc');
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Text>{t('anomaly_plugin.widget.history.sort.text')}</Text>
      <Tooltip
        placement='top'
        title={isOrderDirectionAsc
          ? t('anomaly_plugin.widget.history.sort.asc.text')
          : t('anomaly_plugin.widget.history.sort.desc.text')
        }
      >
        <IconButton
          aria-label="eyeComponent"
          iconOnly
          onClick={switchOrderDirection}
        >
          <Icon
            fontSize='small'
            component={isOrderDirectionAsc ? ArrowUpIcon : ArrowDownIcon}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
