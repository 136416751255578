
import { RequestPostComment } from '@api/interfaces/Comment';
import { useQueryClient } from '@tanstack/react-query';
import { usePostComment } from '../../../api/hooks/usePostComment';

export const usePostAnomalyComment = (projectId: number) => {
  const { postComment } = usePostComment(projectId);
  const queryClient = useQueryClient();

  const postAnomalyComment = (requestPostComment: RequestPostComment) => {
    return postComment({ requestPostComment })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['Anomaly', projectId]
        });
      }).catch((error) => console.log(error));
  };

  return {
    postAnomalyComment
  };
};
