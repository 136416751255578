import { ApiClient } from '@stereograph/teia-system-design/apis';
import {
  TwinObjectQuantityTakeoffResponse,
  TwinSearchDownloadExportInput,
  TwinSearchExportInput
} from './interfaces/QuantityTakeoff';
import { Twin, TwinInfo } from './interfaces/Twin';

export const TwinApi = (client: ApiClient) => ({
  getTwinInfo(twin: Twin) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin`);
    url.searchParams.set('twinProjectType', type);
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<TwinInfo>(request);
  },

  searchExport(twin: Twin, filter: TwinSearchExportInput, pageNumber = 1, pageSize = 100) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/search-export`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body
    });
    return client.sendRequest<TwinObjectQuantityTakeoffResponse>(request);
  },

  downloadExport(twin: Twin, filter: TwinSearchDownloadExportInput) {
    const { projectId, type } = twin;
    const url = client.URL(`projects/${projectId}/twin/download-search-export`);
    url.searchParams.set('twinProjectType', type);
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }
    });

    for (const [key, value] of Object.entries(client.requestHeaders)) {
      request.headers.set(key, value);
    }

    return fetch(request);
  },

  postTwinInfo(
    projectId: number,
    azimuth: number,
    refLatitude: number,
    refLongitude: number,
    elevation: number
  ) {
    const url = client.URL(`projects/${projectId}/twin`);
    const body = JSON.stringify({
      azimuth,
      refLatitude,
      refLongitude,
      elevation
    });
    const request = new Request(url, {
      method: 'PATCH',
      body
    });
    return client.sendRequest<void>(request);
  }
});
