import { Model } from '@api/interfaces/Model';
import { Checkbox } from '@stereograph/teia-system-design';
import { Controller } from 'react-hook-form';
import { usePartialLoadFormContext } from '../hooks/usePartialLoadFormContext';

interface ModelCheckboxProps {
  model: Model;
}

export const ModelCheckbox = (props: ModelCheckboxProps) => {
  const { model } = props;
  const { control } = usePartialLoadFormContext();
  return (
    <Controller
      key={model.ifcModelId}
      render={({ field: { value, onChange, name } }) => (
        <Checkbox
          label={model.name}
          value={String(model.ifcModelId)}
          name={name}
          checked={value.includes(String(model.ifcModelId))}
          onChange={() => {
            if (!value.includes(String(model.ifcModelId))) {
              onChange([...value, String(model.ifcModelId)]);
              return;
            }

            const newSelections = value.filter((id) => id !== String(model.ifcModelId));
            onChange(newSelections);
          }}
        />
      )}
      name={'models'}
      control={control}
      defaultValue={[]}
    />
  );
};
