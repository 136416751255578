import {
  ArrowUturnLeftIcon,
  BackspaceIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';
import { IconButton, Stack, Textarea, Tooltip } from '@stereograph/teia-system-design';
import React from 'react';
import { EditablePrePrompt } from '../PrePromptsConfigTab';

interface PrePromptsStacksProps {
  isLoading: boolean;
  prePrompts: Array<EditablePrePrompt>;
  setPrePrompts: React.Dispatch<React.SetStateAction<Array<EditablePrePrompt>>>;
  isUnchanged: (id: number) => boolean;
  updatePrePrompt: (index: number) => void;
  deletePrePrompt: (index: number) => void;
}

export class PrePromptsStacks extends React.Component<PrePromptsStacksProps> {
  constructor(props: PrePromptsStacksProps) {
    super(props);
  }

  override render() {
    const { isLoading, prePrompts, setPrePrompts, isUnchanged, updatePrePrompt, deletePrePrompt } =
      this.props;

    return (
      <>
        {prePrompts.map((prePrompt, index) => {
          return (
            <Stack direction={'row'}>
              <Textarea
                key={index}
                value={prePrompt.currentText}
                rows={5}
                label={`Pre-Prompt-${index + 1}`}
                onChange={(event) => {
                  const newPrePrompts = [...prePrompts];
                  newPrePrompts[index].currentText = event.target.value;
                  setPrePrompts(newPrePrompts);
                }}
                disabled={isLoading}
                sx={{ flexGrow: 1 }}
              />
              <Stack direction={'column'}>
                <Tooltip title="Confirm Pre-Prompt">
                  <IconButton
                    aria-label="Confirm Pre-Prompt"
                    iconOnly
                    sx={{ width: 40, height: 40 }}
                    disabled={
                      isUnchanged(prePrompt.id) || isLoading || prePrompt.currentText.trim() === ''
                    }
                    onClick={() => updatePrePrompt(index)}
                  >
                    <ClipboardDocumentCheckIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Go back to original Pre-Prompt">
                  <IconButton
                    aria-label="Go back to original Pre-Prompt"
                    iconOnly
                    sx={{ width: 40, height: 40 }}
                    disabled={isUnchanged(prePrompt.id) || isLoading}
                    onClick={() => {
                      const newPrePrompts = [...prePrompts];
                      newPrePrompts[index].currentText = newPrePrompts[index].initialText;
                      setPrePrompts(newPrePrompts);
                    }}
                  >
                    <ArrowUturnLeftIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete Pre-Prompt">
                  <IconButton
                    aria-label="Delete Pre-Prompt"
                    color={'error'}
                    iconOnly
                    sx={{ width: 40, height: 40 }}
                    disabled={isLoading}
                    onClick={() => deletePrePrompt(index)}
                  >
                    <BackspaceIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          );
        })}
      </>
    );
  }
}
