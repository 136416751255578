
import { AnomalyCameraType, AnomalyPerspectiveCamera } from '@api/interfaces/Anomaly';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { PerspectiveCamera, Vector3 } from 'three';
import { DEFAULT_ANOMALY_PERSPECTIVE_CAMERA } from '../widgets/utils';

export const useAnomalyPerspectiveCamera = () => {
  const { viewer } = useTeiaViewerContext();

  if (!viewer.viewport) {
    return null;
  }

  const cameraControls = viewer.viewport.cameraControls;

  if ((cameraControls.camera as PerspectiveCamera).isPerspectiveCamera) {
    const perspectiveCamera = cameraControls.camera as PerspectiveCamera;

    const currentPosition = perspectiveCamera.getWorldPosition(new Vector3());
    const currentDirection = perspectiveCamera.getWorldDirection(new Vector3());

    const anomalyPerspectiveCamera: AnomalyPerspectiveCamera = {
      cameraViewPoint: new Vector3(currentPosition.x, -currentPosition.z, currentPosition.y),
      cameraDirection: new Vector3(currentDirection.x, -currentDirection.z, currentDirection.y),
      type: AnomalyCameraType.PERSPECTIVE,
      cameraUpVector: new Vector3(0, 0, 1),
      aspectRatio: perspectiveCamera.aspect,
      fieldOfView: perspectiveCamera.fov
    };

    return anomalyPerspectiveCamera;
  }

  return DEFAULT_ANOMALY_PERSPECTIVE_CAMERA;
};
