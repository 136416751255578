export default function Typing() {
  return (
    <>
      <style>{`
                .typing {
                    width: 5em;
                    height: 2em;
                    position: relative;
                    padding: 10px;
                    margin-left: 5px;
                    background: #FEECDC;
                    border-radius: 20px;
                }
                .typing__dot {
                    float: left;
                    width: 8px;
                    height: 8px;
                    margin: 0 4px;
                    background: #FF8A4C;
                    border-radius: 50%;
                    opacity: 0;
                    animation: loadingFade 1s infinite;
                }
                .typing__dot:nth-child(1) {
                    animation-delay: 0s;
                }
                .typing__dot:nth-child(2) {
                    animation-delay: 0.2s;
                }
                .typing__dot:nth-child(3) {
                    animation-delay: 0.4s;
                }
                @keyframes loadingFade {
                    0% {
                        opacity: 0;
                    }
                    50% {
                        opacity: 0.8;
                    }
                    100% {
                        opacity: 0;
                    }
                }
            `}</style>
      <div className="typing">
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
        <div className="typing__dot"></div>
      </div>
    </>
  );
}
