import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';

export enum SelectionFilterType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic'
}

export enum SelectionSortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export interface SelectionFilter {
  id: number;
  name: string;
  type: SelectionFilterType;
  filter: TeiaSearchFilter;
  isPublic: boolean;
}

export type NewSelectionFilter = Omit<SelectionFilter, 'id'>;