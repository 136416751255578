import { useEffect, useState } from 'react';

const useTypewriter = (message: string) => {
  const [displayedMessage, setDisplayedMessage] = useState<string>('');

  const duration: number = message.length * 7;

  useEffect(() => {
    if (message) {
      const totalChars = message.length;
      const intervalTime = duration / totalChars;
      let index = 0;

      setDisplayedMessage(message.charAt(0));

      const interval = setInterval(() => {
        index++;
        if (index < totalChars) setDisplayedMessage((prev) => prev + message.charAt(index));
        else clearInterval(interval);
      }, intervalTime);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [message, duration]);

  return displayedMessage;
};

interface TypewriterTextProps {
  message: string;
}

export default function TypewriterText(props: TypewriterTextProps) {
  const { message } = props;
  const displayedMessage = useTypewriter(message);

  return <p>{displayedMessage}</p>;
}
