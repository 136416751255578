export enum PatchDataAction {
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  AddOrUpdate = 'AddOrUpdate'
}

export interface PatchDataProperty {
  propertyName: string;
  name: string;
  value?: string;
  action: PatchDataAction;
}

export interface PatchDataPostRequest {
  name: string;
  description: string;
  image: string;
  twinObjectGuids: Array<string>;
  properties: Array<PatchDataProperty>;
}