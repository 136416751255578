
import { AnomalyScene, ObjectAnomalyInformation } from '@api/interfaces/Anomaly';
import {
  Command,
  IsolateObjectCommand,
  NotUndoableCommandError,
  Viewer
} from '@stereograph/teiaviewer';
import { SetAllObjectVisibleAndHideObjects } from './SetAllObjectVisibleAndHideObjects';
import { SetObjectSelectedAndVisible } from './SetObjectSelectedAndVisible';

export class SetAnomalySceneCommand extends Command {
  readonly name = 'SetAnomalySceneCommand';
  override readonly isUndoable = false;
  private anomalyScene: AnomalyScene;

  constructor(viewer: Viewer, anomalyScene: AnomalyScene) {
    super(viewer);
    this.anomalyScene = anomalyScene;
  }

  protected override async _do() {
    const isVisible = this.anomalyScene.defaultVisibility === ObjectAnomalyInformation.isVisible;

    if (isVisible) {
      const command = new SetAllObjectVisibleAndHideObjects(this.viewer, this.anomalyScene.hidden);
      command.execute();
    } else {
      const command = new IsolateObjectCommand(this.viewer, this.anomalyScene.visible);
      command.execute();
    }

    const command = new SetObjectSelectedAndVisible(this.viewer, this.anomalyScene.selected);
    command.execute();
  }

  protected override async _undo() {
    throw new NotUndoableCommandError(this);
  }
}
