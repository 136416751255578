import { LinearProgress } from '@stereograph/teia-system-design';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTiaPrePromptApi } from '../../../../api/hooks/useTiaPrePromptApi';
import { PrePrompt } from '../../../../api/TiaPrePromptApi';
import { AddPrePromptStack } from './ConfigTabUtils/AddPrePromptStack';
import { PrePromptsStacks } from './ConfigTabUtils/PrePromptStacks';

export interface EditablePrePrompt {
  id: number;
  initialText: string;
  currentText: string;
}

export function PrePromptConfigTabTabContent() {
  const { useGetAllPrePrompts, useDeletePrePrompt, useAddPrePrompt, useUpdatePrePrompt } =
    useTiaPrePromptApi();

  const { data = [], isPending: isLoading, isError } = useGetAllPrePrompts();
  const { mutateAsync: addPrePrompt, isPending: isAdding } = useAddPrePrompt();
  const { mutateAsync: deletePrePrompt, isPending: isDeleting } = useDeletePrePrompt();
  const { mutateAsync: updatePrePrompt, isPending: isUpdating } = useUpdatePrePrompt();

  const isWaiting = isAdding || isDeleting || isLoading || isUpdating;

  const [prePrompts, setPrePrompts] = useState<Array<EditablePrePrompt>>([]);

  const [prePromptToAdd, setPrePromptToAdd] = useState<string>('');

  const isUnchanged = useCallback(
    (id: number) => {
      const prePrompt = prePrompts.find((prePrompt) => prePrompt.id === id);
      return prePrompt?.currentText === prePrompt?.initialText;
    },
    [prePrompts]
  );

  const onUpdatePrePrompt = (index: number) => {
    updatePrePrompt(
      { id: prePrompts[index].id, text: prePrompts[index].currentText },
      {
        onError: (error) => {
          toast.error(`Failed to update Pre-Prompt-${index + 1}`);
          console.error(error);
        },
        onSuccess: () => {
          const newPrePrompts = [...prePrompts];
          newPrePrompts[index].initialText = newPrePrompts[index].currentText;
          setPrePrompts(newPrePrompts);
          toast.success(`Pre-Prompt-${index + 1} updated successfully`);
        }
      }
    );
  };

  const onDeletePrePrompt = (index: number) => {
    deletePrePrompt(
      { id: prePrompts[index].id },
      {
        onError: (error) => {
          toast.error(`Failed to remove Pre-Prompt-${index + 1}`);
          console.error(error);
        },
        onSuccess: () => {
          const newPrePrompts: Array<EditablePrePrompt> = [];
          prePrompts.forEach((prePrompt) => {
            if (prePrompt.id !== prePrompts[index].id) {
              newPrePrompts.push(prePrompt);
            }
          });
          setPrePrompts(newPrePrompts);
          toast.success(`Pre-Prompt-${index + 1} removed successfully`);
        }
      }
    );
  };

  const onAddPrePrompt = () => {
    if (prePromptToAdd.trim() !== '') {
      addPrePrompt(
        { text: prePromptToAdd },
        {
          onError: (error) => {
            toast.error('Failed to add Pre-Prompt');
            console.error(error);
          },
          onSuccess: (data: PrePrompt) => {
            setPrePrompts([
              ...prePrompts,
              { id: data.promptId, currentText: data.prompt, initialText: data.prompt }
            ]);
            setPrePromptToAdd('');
            toast.success('Pre-Prompt added successfully');
          }
        }
      );
    }
  };

  useEffect(() => {
    setPrePrompts(
      data.map((item) => ({
        id: item.promptId,
        currentText: item.prompt,
        initialText: item.prompt
      }))
    );
  }, [data]);

  if (isError) {
    return 'Error fetching preprompts';
  }

  return (
    <div className="st-gap-5 st-flex st-flex-col">
      <p className="st-font-semibold">Pre-Prompts Config:</p>
      {isLoading && <LinearProgress showPercentage={false} />}
      <PrePromptsStacks
        isLoading={isWaiting}
        prePrompts={prePrompts}
        setPrePrompts={setPrePrompts}
        isUnchanged={isUnchanged}
        updatePrePrompt={onUpdatePrePrompt}
        deletePrePrompt={onDeletePrePrompt}
      />
      <AddPrePromptStack
        isLoading={isWaiting}
        prePromptToAdd={prePromptToAdd}
        setPrePromptToAdd={setPrePromptToAdd}
        addPrePrompt={onAddPrePrompt}
      />
    </div>
  );
}
