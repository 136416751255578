import { Comment } from '@api/interfaces/Comment';
import { Box, Divider, Stack, Text } from '@stereograph/teia-system-design';
import { useCanDeleteComment } from '../hooks/useCanDeleteComment';
import { CommentHeading } from './Comment';
import { DeleteAnomalyCommentForm } from './DeleteAnomalyCommentForm';

interface TeiaAnomalyCommentProps {
  comment: Comment,
  anomalyId: number,
}

export const TeiaAnomalyComment = (props: TeiaAnomalyCommentProps) => {
  const { comment, anomalyId } = props;
  const canDelete = useCanDeleteComment(comment.createdBy);

  return (
    <Box
      sx={{
        paddingBottom: 4
      }}
    >
      <Stack
        direction="row"
        spacing={4}
        sx={{
          paddingBottom: 4
        }}
      >
        <Stack direction="column" sx={{ width: '100%' }} spacing={8}>
          <Stack direction="row" justifyContent="space-between">
            <CommentHeading comment={comment} />
            {canDelete && (
              <DeleteAnomalyCommentForm commentId={comment.id} anomalyId={anomalyId} />
            )}
          </Stack>
          <Text>{`${comment.value}`}</Text>
        </Stack>
      </Stack>
      <Divider />
    </Box>
  );
};
