import { useEffect, useRef } from 'react';
import AssistantMessage from '../../messages/AssistantMessage';
import SystemMessage from '../../messages/SystemMessage';
import UserMessage from '../../messages/UserMessage';
import Typing from '../../typing/Typing';
import { ChatBotWidgetInput } from './ChatBotWidgetInput';
import { useConversationContext } from './ConversationContext';

export function ChatBotWidgetContent() {
  const { conversationId, messages, isLoading } = useConversationContext();

  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chat = chatContainerRef.current;
    if (chat) {
      const observer = new MutationObserver(() => {
        chat.scrollTop = chat.scrollHeight;
      });

      observer.observe(chat, {
        childList: true,
        subtree: true,
        characterData: true
      });

      return () => observer.disconnect();
    }
    return () => {};
  }, []);

  return (
    <div className="st-flex st-flex-col st-w-full st-h-full st-p-1 st-gap-1 st-justify-center">
      <div
        ref={chatContainerRef}
        className="st-flex-grow st-overflow-y-auto st-flex st-flex-col st-gap-1"
      >
        {messages.map((message, index) => {
          switch (message.role) {
            case 'assistant':
              return (
                <AssistantMessage
                  key={index}
                  index={index}
                  message={message}
                  conversationId={conversationId}
                  isLastMessage={index === messages.length - 1}
                />
              );
            case 'user':
              return <UserMessage key={index} index={index} message={message.text} />;
            case 'system':
              return <SystemMessage key={index} index={index} message={message.text} />;
          }
        })}
        {isLoading && <Typing />}
      </div>
      <ChatBotWidgetInput />
    </div>
  );
}
