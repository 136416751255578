import { Text } from '@stereograph/teia-system-design';
import { useEffect, useState } from 'react';

interface TypewriterTextProps {
  content: string;
  speed?: number;
}

export const TypewriterText = (props: TypewriterTextProps) => {
  const { content, speed = 7 } = props;
  const [displayedMessage, setDisplayedMessage] = useState<string>('');

  useEffect(() => {
    const size = content.length;
    const duration: number = size * speed;

    const intervalTime = duration / size;
    let index = 0;

    setDisplayedMessage(content.charAt(0));

    const interval = setInterval(() => {
      index++;
      if (index < size) setDisplayedMessage((prev) => prev + content.charAt(index));
      else clearInterval(interval);
    }, intervalTime);
    return () => clearInterval(interval);
  }, [content]);

  return <Text fontSize={14}>{displayedMessage}</Text>;
};
