import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/apis';
import {
  Anomaly,
  AnomalyCsvImportResponse,
  AnomalyFileType,
  AnomalyHistory,
  AnomalyHistoryQueryParams,
  GetAnomaliesQueryParams,
  RequestPostAnomaly
} from './interfaces/Anomaly';
import { TwinType } from './interfaces/Twin';


export const AnomalyApi = (client: ApiClient) => ({
  getAnomaly(projectId: number, anomalyId: number) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}`);

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Anomaly>(request);
  },

  postAnomaly(projectId: number, anomaly: RequestPostAnomaly) {
    const url = client.URL(`projects/${projectId}/anomalies`);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(anomaly)
    });

    return client.sendRequest(request);
  },

  patchAnomaly(projectId: number, anomalyId: number, anomaly: RequestPostAnomaly) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}`);

    const request = new Request(url, {
      method: 'PUT',
      body: JSON.stringify(anomaly)
    });

    return client.sendRequest(request);
  },

  getAnomalyHistory(
    projectId: number,
    anomalyId: number,
    anomalyHistoryQueryParams: AnomalyHistoryQueryParams
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyId}/histories`);
    url.searchParams.set('pageNumber', anomalyHistoryQueryParams.pageNumber.toString());
    url.searchParams.set('pageSize', anomalyHistoryQueryParams.pageSize.toString());
    if (anomalyHistoryQueryParams.orderBy) {
      url.searchParams.set('orderBy', anomalyHistoryQueryParams.orderBy);
    }
    if (anomalyHistoryQueryParams.orderDirection) {
      url.searchParams.set('orderDirection', anomalyHistoryQueryParams.orderDirection);
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<AnomalyHistory>>(request);
  },

  getAnomalies(projectId: number, anomaliesQueryParams: GetAnomaliesQueryParams) {
    const url = client.URL(`projects/${projectId}/anomalies`);
    url.searchParams.set('pageSize', String(anomaliesQueryParams.pageSize));
    url.searchParams.set('pageNumber', String(anomaliesQueryParams.pageNumber));

    if (anomaliesQueryParams.status) {
      url.searchParams.set('status', anomaliesQueryParams.status);
    }
    if (anomaliesQueryParams.priority) {
      url.searchParams.set('priority', anomaliesQueryParams.priority);
    }
    if (anomaliesQueryParams.type) {
      url.searchParams.set('type', anomaliesQueryParams.type);
    }
    if (anomaliesQueryParams.fileIds) {
      anomaliesQueryParams.fileIds.forEach((fileId) => {
        url.searchParams.append('filesIds', String(fileId));
      });
    }
    if (anomaliesQueryParams.author) {
      url.searchParams.set('author', anomaliesQueryParams.author);
    }
    if (anomaliesQueryParams.title) {
      url.searchParams.set('title', anomaliesQueryParams.title);
    }
    if (anomaliesQueryParams.isExported === true) {
      url.searchParams.set('isExported', 'true');
    }

    if (anomaliesQueryParams.orderBy) {
      url.searchParams.set('orderBy', anomaliesQueryParams.orderBy);
    }

    if (anomaliesQueryParams.orderDirection) {
      url.searchParams.set('orderDirection', anomaliesQueryParams.orderDirection);
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Anomaly>>(request);
  },

  async exportAnomalies(
    projectId: number,
    anomalyIds: Array<number>,
    anomalyUrlType: AnomalyFileType
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${anomalyUrlType}/export`);

    anomalyIds.forEach((anomalyId) => {
      url.searchParams.append('anomalyIds', String(anomalyId));
    });

    const request = new Request(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    for (const [key, value] of Object.entries(client.requestHeaders)) {
      request.headers.set(key, value);
    }

    return fetch(request);
  },

  async importBcfAnomaly(
    projectId: number,
    files: Array<File>,
    twinProjectType: TwinType
  ): Promise<unknown> {
    const url = client.URL(`projects/${projectId}/anomalies/${AnomalyFileType.BCF}/import`);
    url.searchParams.set('twinProjectType', twinProjectType);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const request = new Request(url, {
      method: 'POST',
      body: formData
    });

    for (const [key, value] of Object.entries(client.requestHeaders)) {
      if (key.toLowerCase() !== 'content-type') {
        request.headers.set(key, value);
      }
    }

    const response = await fetch(request);

    if (!response.ok) {
      throw new Error('An error occured');
    }

    return response;
  },

  async importCsvAnomaly(
    projectId: number,
    files: Array<File>,
    twinProjectType: TwinType,
  ) {
    const url = client.URL(`projects/${projectId}/anomalies/${AnomalyFileType.Excel}/import`);
    url.searchParams.set('twinProjectType', twinProjectType);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    const request = new Request(url, {
      method: 'POST',
      body: formData
    });

    for (const [key, value] of Object.entries(client.requestHeaders)) {
      if (key.toLowerCase() !== 'content-type') {
        request.headers.set(key, value);
      }
    }

    const response = await fetch(request);

    if (!response.ok) {
      throw new Error('An error occured');
    }

    const anomalyCsvImportResponse: AnomalyCsvImportResponse = await response.json();

    return anomalyCsvImportResponse;
  }
});
