import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';

export const teiaQuantityTakeoffQueryKeys = {
  columns: ['quantityTakeOff', 'columns'] as const,
  objects: (
    rule: TeiaSearchFilter,
    columns: Array<string>,
    groupBy: string,
    pageIndex: number,
    pageSize: number
  ) =>
    [
      'quantityTakeOff',
      'objects',
      {
        rule,
        columns,
        groupBy,
        pageIndex,
        pageSize
      }
    ] as const,
  download: (rule: TeiaSearchFilter, columns: Array<string>, groupBy: string) =>
    [
      'quantityTakeOff',
      'objects',
      'export',
      {
        rule,
        columns,
        groupBy
      }
    ] as const
};
