import { NumberController, PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { ShowTeiaOpenDataWidget } from './ToolbarItems';
import { TeiaOpenDataWidget } from './widgets/TeiaOpenDataWidget';

declare global {
  interface ViewerPluginRegistry {
    openData: OpenDataPlugin;
  }
}

const Settings = () => ({
  guidChunkSize: new NumberController(1_000, { min: 1, max: 100_000, step: 1 }),
  maxPostRequestParallelism: new NumberController(10, {
    min: 1,
    max: 1_000,
    step: 1
  })
});


const PluginBaseClass = PluginBuilder.NewPlugin('OpenDataPlugin', Settings);

export class OpenDataPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = { ShowTeiaOpenDataWidget };

  override onRegister(): void {
    setupPluginsTranslation();
    this._setupWidgets();
  }

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('TeiaOpenData', ({ viewportRect }) => {
      const { width, height } = viewportRect;
      return new TeiaOpenDataWidget(this.viewer, {
        x: width - width / 3 - 5,
        y: 5,
        width: width / 3,
        height: height - 10,
        minWidth: 400,
        minHeight: 400
      });
    });
  }
}
