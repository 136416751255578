import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { TiaPrePromptApi } from '../TiaPrePromptApi';

const PrePromptQueryKey = 'PrePrompt';

export const useTiaPrePromptApi = () => {
  const { plugin } = useViewerPlugin('chatBotLegacy');
  const client = plugin.elliotAPIClient;
  const api = TiaPrePromptApi(client);
  const queryClient = useQueryClient();

  const useGetAllPrePrompts = () => {
    return useQuery({
      queryKey: [PrePromptQueryKey],
      queryFn: () => api.getAllPrePrompts()
    });
  };

  const useDeletePrePrompt = () =>
    useMutation({
      mutationFn: async (t: { id: number }) => {
        queryClient.invalidateQueries({ queryKey: [PrePromptQueryKey] });
        return api.deletePrePrompt(t.id);
      }
    });

  const useUpdatePrePrompt = () =>
    useMutation({
      mutationFn: async (t: { id: number; text: string }) => {
        queryClient.invalidateQueries({ queryKey: [PrePromptQueryKey] });
        return api.updatePrePrompt(t.id, t.text);
      }
    });

  const useAddPrePrompt = () =>
    useMutation({
      mutationFn: async (t: { text: string }) => {
        queryClient.invalidateQueries({ queryKey: [PrePromptQueryKey] });
        return api.addPrePrompt(t.text);
      }
    });

  return { useGetAllPrePrompts, useDeletePrePrompt, useUpdatePrePrompt, useAddPrePrompt };
};
