import { NumberController, PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { ShowTeiaQuantityTakeoffWidgetToolbarItem } from './ToolbarItems';
import { TeiaQuantityTakeoffWidget } from './widget/TeiaQuantityTakeoffWidget';

declare global {
  interface ViewerPluginRegistry {
    qto: QuantityTakeOffPlugin;
  }
}

const Settings = () => ({
  maxObjectsLimit: new NumberController(50_000, { min: 0, max: 50_000, step: 1 })
});

const PluginBaseClass = PluginBuilder.NewPlugin('QuantityTakeOff', Settings);

export class QuantityTakeOffPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = {
    ShowQTOWidget: ShowTeiaQuantityTakeoffWidgetToolbarItem
  };

  override onRegister(): void {
    setupPluginsTranslation();
    this._setupWidgets();
  }

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('TeiaQuantityTakeOff', ({ searchRules }) => {
      return new TeiaQuantityTakeoffWidget(this.viewer, { searchRules: searchRules });
    });
  }
}
