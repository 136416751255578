import { ApiClient } from '@stereograph/teia-system-design/apis';
import { PluginBuilder, StringController, TeiaViewer } from '@stereograph/teiaviewer';
import {
  ChatBotToolbarItem,
  ChatBotWidget,
  ElliotConfigToolbarItem,
  ElliotConfigWidget
} from './components';

declare global {
  interface ViewerPluginRegistry {
    chatBotLegacy: ChatBotPluginLegacy;
  }
}

const Settings = () => ({
  tiaApiUrl: new StringController('')
});

const PluginBaseClass = PluginBuilder.NewPlugin('ChatBotPluginLegacy', Settings);

export class ChatBotPluginLegacy extends PluginBaseClass<TeiaViewer> {
  declare elliotAPIClient: ApiClient;

  static override readonly ToolbarItems = {
    ElliotConfigToolbarItem,
    ChatBotToolbarItem
  };

  override onRegister(): void {
    this.elliotAPIClient = new ApiClient(
      this.settings.tiaApiUrl.value,
      this.viewer.settings.twinApi.token.value
    );
    this._setupWidgets();

    this.viewer.settings.twinApi.token.onChange.connect(this._onTokenChange);
  }

  override onUnregister(): void {
    this.viewer.settings.twinApi.token.onChange.disconnect(this._onTokenChange);
  }

  private _onTokenChange = () => {
    this.elliotAPIClient.token = this.viewer.settings.twinApi.token.value;
  };

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('ElliotConfigWidget', () => {
      return new ElliotConfigWidget(this.viewer, {
        x: 5,
        y: 5,
        width: 450,
        height: 550,
        minHeight: 200,
        minWidth: 300
      });
    });
    this.viewer.widgetFactory.registerWidget('ChatBotWidget', () => {
      return new ChatBotWidget(this.viewer, {
        x: 5,
        y: 5,
        width: 450,
        height: 550,
        minHeight: 200,
        minWidth: 300
      });
    });
  }
}
