import {
  AnomalyCamera,
  AnomalyGuids,
  AnomalyPriority,
  AnomalyStatus,
  AnomalyType,
  RequestPostAnomaly
} from '@api/interfaces/Anomaly';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWidgetContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useAnomalyApi } from '../api/useAnomalyApi';
import { useAnomalyContext } from '../context/AnomalyContext';
import { AnomalyFormSchema, TeiaAnomalyForm, anomalyFormSchema } from './TeiaAnomalyForm';

interface TeiaCreateAnomalyFormProps {
  snapshot: string;
  anomalyGuids: AnomalyGuids;
  camera: AnomalyCamera;
}

export const TeiaCreateAnomalyForm = (props: TeiaCreateAnomalyFormProps) => {
  const { snapshot, anomalyGuids, camera } = props;
  const { widget } = useWidgetContext();
  const { projectId, twinProjectType, fileIds } = useAnomalyContext();
  const { postAnomaly } = useAnomalyApi(projectId);
  const { t } = usePluginTranslation();

  const methods = useForm<AnomalyFormSchema>({
    resolver: zodResolver(anomalyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      anomalySchema: {
        type: AnomalyType.Clash,
        priority: AnomalyPriority.Critical,
        status: AnomalyStatus.Active,
        stateByGuid: anomalyGuids,
        description: '',
        title: ''
      }
    }
  });
  const { handleSubmit } = methods;

  const onCloseAnomalyWidget = () => {
    widget.close();
  };

  const onSubmit = (data: AnomalyFormSchema) => {
    const anomaly: RequestPostAnomaly = {
      ...data.anomalySchema,
      fileId: fileIds.length === 1 ? fileIds[0] : undefined,
      twinProjectId: fileIds.length === 1 ? undefined : projectId,
      twinProjectType: twinProjectType,
      snapshot: snapshot,
      dueDate: new Date().toISOString(),
      createAnomalyViewPointCommand: {
        ...camera
      }
    };

    toast.promise(postAnomaly({ anomaly }), {
      loading: t('anomaly_plugin.widget.form.submit.loading.text'),
      success: () => {
        onCloseAnomalyWidget();
        return t('anomaly_plugin.widget.form.submit.success.text');
      },
      error: t('anomaly_plugin.widget.form.submit.error.text')
    });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TeiaAnomalyForm
          buttonLabel={t('anomaly_plugin.widget.form.submit.create.label')}
          snapshot={snapshot}
        />
      </form>
    </FormProvider>
  );
};
