import { UserMessage } from '../ChatMessage';
import { MessageHeader } from '../common/MessageHeader';
import { MessageText } from '../common/MessageText';
import { MessageView } from '../common/MessageView';

export interface UserMessageViewProps {
  message: UserMessage;
}

export const UserMessageView = (props: UserMessageViewProps) => {
  const { message } = props;
  return (
    <MessageView color="primary.light" alignment="right">
      <MessageHeader title="👤 You" />
      <MessageText content={message.text} />
    </MessageView>
  );
};
