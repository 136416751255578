import { ApiClient } from '@stereograph/teia-system-design/apis';
import { GetModelsQueryParams, ModelResponse } from './interfaces/Model';

export const ModelApi = (client: ApiClient) => ({
  getModels(projectId: number, options: GetModelsQueryParams) {
    const url = client.URL(`projects/${projectId}/models`);
    if (options.isInTwin) {
      url.searchParams.set('isInTwin', String(options.isInTwin));
    }
    if (options.pageNumber) {
      url.searchParams.set('pageNumber', String(options.pageNumber));
    }
    if (options.pageNumber) {
      url.searchParams.set('pageSize', String(options.pageSize));
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ModelResponse>(request);
  }
});
