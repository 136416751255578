import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import english from '../../locales/en/translation.json';
import spanish from '../../locales/es/translation.json';
import french from '../../locales/fr/translation.json';
import polish from '../../locales/pl/translation.json';

export function setupPluginsTranslation() {
  if (!i18next.hasResourceBundle('en', 'teia-viewer-plugins')) {
    i18next.addResourceBundle('en', 'teia-viewer-plugins', english);
    i18next.addResourceBundle('fr', 'teia-viewer-plugins', french);
    i18next.addResourceBundle('es', 'teia-viewer-plugins', spanish);
    i18next.addResourceBundle('pl', 'teia-viewer-plugins', polish);
    i18next.loadNamespaces(['teia-viewer-plugins']);
  }
}

export const usePluginTranslation = () => {
  return useTranslation('teia-viewer-plugins');
};

export type FlattenObjectKeys<T extends Record<string, unknown>, Key = keyof T> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${Key}.${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

export type ViewerPluginsTranslationKey = FlattenObjectKeys<typeof english>;

export const t = (value: ViewerPluginsTranslationKey, params?: any) => {
  return i18next.t(value, { ns: 'teia-viewer-plugins', ...params });
};
