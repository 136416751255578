import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ChatMessageInput, MessageFeedback, TiaConversationApi } from '../TiaConversationApi';
import { QuotaQueryKey } from './useTiaQuotaApi';

export const useTiaConversationApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  const api = TiaConversationApi(client);
  const queryClient = useQueryClient();

  const useCreateConversation = () =>
    useMutation({
      mutationFn: async (t: { flowId: string }) => {
        return api.createConversation(t.flowId);
      }
    });

  const usePostFeedback = () =>
    useMutation({
      mutationFn: async (t: {
        conversationId: string;
        messageId: string;
        feedback: MessageFeedback;
      }) => {
        return api.postFeedback(t.conversationId, t.messageId, t.feedback);
      }
    });

  const usePostMessage = () =>
    useMutation({
      mutationFn: async (t: { conversationId: string; input: ChatMessageInput }) => {
        await queryClient.invalidateQueries({ queryKey: [QuotaQueryKey] });
        return api.postMessage(t.conversationId, t.input);
      }
    });

  return { usePostFeedback, usePostMessage, useCreateConversation };
};
