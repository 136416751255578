interface EnvironmentVariables {
  VITE_ELLIOT_API_BASE_URL: string;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface ImportMetaEnv extends EnvironmentVariables {}

  interface ImportMeta {
    readonly env: ImportMetaEnv;
  }
}

const DOCKER_ENV = (window as any).DOCKER_ENV ?? {};

export const ENV_VARIABLES: EnvironmentVariables = {
  VITE_ELLIOT_API_BASE_URL: import.meta.env.VITE_ELLIOT_API_BASE_URL,
  ...DOCKER_ENV
};
