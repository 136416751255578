import { TwinObjectQuantityTakeoffAggregate } from '@stereograph/teia-system-design/apis';
import { usePluginTranslation } from '@translation';
import { TeiaQuantityTakeoffTableAggregateVisibilityButton } from './TeiaQuantityTakeoffTableAggregateVisibilityButton';
import { TeiaQuantityTakeoffTableCellAggregateItem } from './TeiaQuantityTakeoffTableCellAggregateItem';
import { useAggregateVisibility } from './TeiaQuantityTakeoffVisibilityContext';

interface TeiaQuantityTakeoffAggregatesProps {
  aggregates: TwinObjectQuantityTakeoffAggregate;
  column: string;
}

export const TeiaQuantityTakeoffAggregates = (props: TeiaQuantityTakeoffAggregatesProps) => {
  const { aggregates, column } = props;
  const { t } = usePluginTranslation();
  const { columnsAggregateVisibility } = useAggregateVisibility();
  const visibility = columnsAggregateVisibility[column];
  return (
    <div className={'st-relative st-group st-pr-8'}>
      <div
        className={
          'st-absolute st-top-1/2 -st-translate-y-1/2 -st-right-1 st-invisible group-hover:st-visible st-opacity-0 group-hover:st-opacity-100 st-transition-all'
        }
      >
        <TeiaQuantityTakeoffTableAggregateVisibilityButton column={column} closeOnChange={true} />
      </div>
      {visibility.count && (
        <TeiaQuantityTakeoffTableCellAggregateItem
          label={t('quantity_takeoff_plugin.aggregate_cell_item.count_label')}
          value={aggregates.count}
        />
      )}
      {visibility.sum && (
        <TeiaQuantityTakeoffTableCellAggregateItem
          label={t('quantity_takeoff_plugin.aggregate_cell_item.sum_label')}
          value={aggregates.sum}
        />
      )}
      {visibility.min && (
        <TeiaQuantityTakeoffTableCellAggregateItem
          label={t('quantity_takeoff_plugin.aggregate_cell_item.min_label')}
          value={aggregates.min}
        />
      )}
      {visibility.max && (
        <TeiaQuantityTakeoffTableCellAggregateItem
          label={t('quantity_takeoff_plugin.aggregate_cell_item.max_label')}
          value={aggregates.max}
        />
      )}
      {visibility.average && (
        <TeiaQuantityTakeoffTableCellAggregateItem
          label={t('quantity_takeoff_plugin.aggregate_cell_item.average_label')}
          value={aggregates.avg}
        />
      )}
    </div>
  );
};
