import React, { useMemo, useState } from 'react';
import { Flow } from '../../api/TiaFlowApi';

export type FlowConfigMode = 'UpdateFlow' | 'ListFlows' | 'CreateFlow';

export interface FlowConfigContext {
  mode: FlowConfigMode;
  flow?: Flow;
  setMode: (mode: FlowConfigMode, flow?: Flow) => void;
}

const Context = React.createContext<FlowConfigContext | null>(null);

interface FlowConfigContextProviderProps {
  children?: React.ReactNode;
}

export const FlowConfigContextProvider = (props: FlowConfigContextProviderProps) => {
  const { children } = props;

  const [state, setState] = useState<{ mode: FlowConfigMode; flow?: Flow }>({ mode: 'ListFlows' });

  const setMode = (mode: FlowConfigMode, flow?: Flow) => {
    setState({ mode, flow });
  };

  const ctx = useMemo(
    () => ({
      setMode,
      mode: state.mode,
      flow: state.flow
    }),
    [state, setMode]
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useFlowConfigContext = () => {
  const ctx = React.useContext(Context);
  if (!ctx) {
    throw new Error('ConfigContext not found');
  }
  return ctx;
};
