import { SystemMessage } from '../ChatMessage';
import { MessageHeader } from '../common/MessageHeader';
import { MessageText } from '../common/MessageText';
import { MessageView } from '../common/MessageView';

interface SystemMessageViewProps {
  message: SystemMessage;
}

export const SystemMessageView = (props: SystemMessageViewProps) => {
  const { message } = props;
  return (
    <MessageView color="info.light">
      <MessageHeader title="🛠️ System" />
      <MessageText content={message.text} />
    </MessageView>
  );
};
