import { Anomaly } from '@api/interfaces/Anomaly';
import {
  MRT_RowSelectionState,
  MRT_SortingState,
  Table,
  TableConfigs,
  TableProps,
  createMRTColumnHelper
} from '@stereograph/teia-system-design';
import { ColumnFiltersState, OnChangeFn, SortingState } from '@tanstack/react-table';
import { usePluginTranslation } from '@translation';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useAnomalyPriorities } from '../hooks/useAnomalyPriorities';
import { useAnomalyStatus } from '../hooks/useAnomalyStatus';
import { useAnomalyTypes } from '../hooks/useAnomalyTypes';
import { ColumnFilter } from '../widgets/TeiaAnomalyResumeWidgetContent';
import { ActionsCell } from './table/ActionsCell';
import { ClampedTextCell } from './table/ClampedTextCell';
import { ImageCell } from './table/ImageCell';
import { TeiaAnomalyResumeTableToolbar } from './table/TeiaAnomalyResumeTableToolbar';

interface TeiaAnomalyResumeTableProps {
  anomalies: Array<Anomaly>;
  pagination: { pageIndex: number; pageSize: number };
  setPagination: Dispatch<
    SetStateAction<{
      pageIndex: number;
      pageSize: number;
    }>
  >;
  sorting: MRT_SortingState;
  setSorting: OnChangeFn<SortingState>;
  rowCount: number;
  columnFilters: Array<ColumnFilter>;
  setColumnFilters: Dispatch<SetStateAction<Array<ColumnFilter>>>;
  isLoading?: boolean;
}

export const TeiaAnomalyResumeTable = (props: TeiaAnomalyResumeTableProps) => {
  const {
    anomalies,
    pagination,
    rowCount,
    setPagination,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    isLoading = false
  } = props;

  const { t } = usePluginTranslation();
  const anomalyPriorities = useAnomalyPriorities();
  const anomalyStatus = useAnomalyStatus();
  const anomalyTypes = useAnomalyTypes();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const columnBuilder = createMRTColumnHelper<Anomaly>();

  const columns = useMemo(() => [
    columnBuilder.accessor('snapshot', {
      id: 'snapshot',
      header: t('anomaly_plugin.resume_widget.table.columns.snapshot.text'),
      Cell: ImageCell,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableSorting: false
    }),
    columnBuilder.accessor('title', {
      id: 'title',
      header: t('anomaly_plugin.resume_widget.table.columns.title.text'),
      Cell: ClampedTextCell,
      enableColumnFilter: true,
      filterVariant: 'text',
      filterFn: 'includesString',
      enableColumnActions: false,
      size: 130,
      maxSize: 180
    }),
    columnBuilder.accessor('commentCounts', {
      id: 'commentCounts',
      header: t('anomaly_plugin.resume_widget.table.columns.comments.text'),
      enableColumnFilter: false,
      filterVariant: 'text',
      enableColumnActions: false,
      filterFn: 'inNumberRange'
    }),
    columnBuilder.accessor('status', {
      id: 'status',
      header: t('anomaly_plugin.resume_widget.table.columns.status.text'),
      Cell: ({ cell }) => anomalyStatus[cell.getValue()],
      enableColumnFilter: true,
      filterVariant: 'select',
      filterSelectOptions: Object.entries(anomalyStatus).map(([key, value]) => ({
        value: key,
        label: value
      })),
      enableColumnActions: false,
      size: 50
    }),
    columnBuilder.accessor('priority', {
      id: 'priority',
      header: t('anomaly_plugin.resume_widget.table.columns.priority.text'),
      Cell: ({ cell }) => anomalyPriorities[cell.getValue()],
      filterVariant: 'select',
      filterSelectOptions: Object.entries(anomalyPriorities).map(([key, value]) => ({
        value: key,
        label: value
      })),
      enableColumnActions: false,
      size: 50
    }),
    columnBuilder.accessor('type', {
      id: 'type',
      header: t('anomaly_plugin.resume_widget.table.columns.type.text'),
      Cell: ({ cell }) => anomalyTypes[cell.getValue()],
      enableColumnFilter: true,
      filterVariant: 'select',
      filterSelectOptions: Object.entries(anomalyTypes).map(([key, value]) => ({
        value: key,
        label: value
      })),
      enableColumnActions: false,
      size: 50
    }),
    columnBuilder.accessor('author', {
      id: 'author',
      header: t('anomaly_plugin.resume_widget.table.columns.creation.author.text'),
      Cell: ({ cell }) => cell.getValue(),
      enableColumnFilter: false,
      enableColumnActions: false
    }),
    columnBuilder.accessor('creationDate', {
      id: 'creationDate',
      header: t('anomaly_plugin.resume_widget.table.columns.creation.date.text'),
      Cell: ({ cell }) =>
        t('date', {
          val: new Date(cell.getValue()),
          formatParams: { val: { year: 'numeric', month: '2-digit', day: '2-digit' } }
        }),
      enableColumnFilter: false,
      enableColumnActions: false
    }),
    columnBuilder.accessor('updatedAuthor', {
      id: 'updatedAuthor',
      header: t('anomaly_plugin.resume_widget.table.columns.update.author.text'),
      Cell: ({ cell }) => cell.getValue(),
      enableColumnFilter: false,
      enableColumnActions: false
    }),
    columnBuilder.accessor('updateDate', {
      id: 'updateDate',
      header: t('anomaly_plugin.resume_widget.table.columns.update.date.text'),
      Cell: ({ cell }) =>
        t('date', {
          val: new Date(cell.getValue()),
          formatParams: { val: { year: 'numeric', month: '2-digit', day: '2-digit' } }
        }),
      enableColumnActions: false
    })
  ], []);

  const table: TableProps<Anomaly>['tableOptions'] = {
    ...TableConfigs.DefaultConfig,
    columns,
    data: anomalies,
    getRowId: (originalRow, index) => {
      if (originalRow.id) {
        return originalRow.id.toString();
      }

      return index.toString();
    },
    enableColumnActions: true,
    enableRowActions: true,
    enableRowSelection: true,
    state: {
      rowSelection,
      pagination: pagination,
      columnFilters: columnFilters,
      sorting,
      isLoading,
    },
    manualPagination: true,
    muiTableBodyCellProps: {
      sx: {
        whiteSpace: 'normal',
      }
    },
    onPaginationChange: setPagination,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    rowCount: rowCount,
    onRowSelectionChange: setRowSelection,
    muiPaginationProps: {
      rowsPerPageOptions: [5, 10, 20, 50],
    },
    muiTableBodyRowProps: { sx: { maxHeight: 100, height: 100 } },
    enableExpandAll: false,
    positionActionsColumn: 'last',
    renderRowActions: ActionsCell,
    renderTopToolbarCustomActions: TeiaAnomalyResumeTableToolbar,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    columnFilterDisplayMode: 'popover',
    initialState: {
      columnVisibility: {
        author: false,
        creationDate: false,
        updatedAuthor: false,
        updateDate: false,
        commentIds: false,
      },
      density: 'compact',
      columnPinning: {
        right: ['mrt-row-actions'],
      },
    },
  };

  return <Table tableOptions={table} />;
};
