import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import {
  Message,
  Modal,
  ModalContent,
  ModalHeader,
  Stack,
  TableIconButton,
  Tooltip
} from '@stereograph/teia-system-design';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useDownloadSearchExport } from '../../hooks/useDownloadSearchExport';
import { TeiaQuantityTakeoffExportForm } from '../export/TeiaQuantityTakeoffExportForm';

interface TeiaQuantityTakeoffExportProps {
  searchRules: TeiaSearchFilter;
  tableColumns: Array<string>;
  groupBy: string | null;
}

export const TeiaQuantityTakeoffExport = (props: TeiaQuantityTakeoffExportProps) => {
  const { searchRules, tableColumns, groupBy } = props;
  const { t } = usePluginTranslation();
  const { downloadSearchExport, isDownloading } = useDownloadSearchExport({
    searchRules: searchRules,
    tableColumns: tableColumns,
    groupBy: groupBy ?? null
  });
  const [modalShow, setModalShow] = useState(false);

  const openModal = () => {
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
  };

  const downloadExport = (name: string) => {
    downloadSearchExport(name);
    closeModal();
  };
  return (
    <>
      <Tooltip
        id={'teia-qto-export-tooltip'}
        title={t('quantity_takeoff_plugin.export_button_label')}
        placement={'bottom'}
      >
        <TableIconButton
          onClick={openModal}
          disabled={isDownloading}
          aria-label={t('quantity_takeoff_plugin.export_button_label')}
          icon={ArrowDownTrayIcon}
        />
      </Tooltip>
      <Modal
        id={'teia-qto-modal-export'}
        open={modalShow}
        onClose={closeModal}
        maxWidth={'md'}
        fullWidth={true}
      >
        <ModalHeader>{t('quantity_takeoff_plugin.export_modal.title_label')}</ModalHeader>
        <ModalContent>
          <Stack spacing={4}>
            <Message color={'secondary'} textAlignment={'center'}>
              {t('quantity_takeoff_plugin.export_modal.info_text')}
            </Message>
            <TeiaQuantityTakeoffExportForm
              onSubmit={downloadExport}
              onCancel={() => closeModal()}
            />
          </Stack>
        </ModalContent>
      </Modal>
    </>
  );
};
