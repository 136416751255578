import {
  AnomalyHistory,
  AnomalyHistoryProperty,
  AnomalyHistoryStatus
} from '@api/interfaces/Anomaly';
import { Stack, Text } from '@stereograph/teia-system-design';
import { TeiaAnomalyHistoryItemAdded } from './TeiaAnomalyHistoryItemAdded';
import { TeiaAnomalyHistoryItemCreated } from './TeiaAnomalyHistoryItemCreated';
import { TeiaAnomalyHistoryItemDeleted } from './TeiaAnomalyHistoryItemDeleted';
import { TeiaAnomalyHistoryItemUpdated } from './TeiaAnomalyHistoryItemUpdated';

interface TeiaAnomalyHistoryItemProps {
  anomalyHistory: AnomalyHistory;
}

export const TeiaAnomalyHistoryItem = (props: TeiaAnomalyHistoryItemProps) => {
  const { anomalyHistory } = props;

  const renderAnomalyItemByState = (
    anomalyHistoryState: string,
    property: AnomalyHistoryProperty
  ) => {
    switch (anomalyHistoryState) {
      case AnomalyHistoryStatus.ADDED:
        return (
          <TeiaAnomalyHistoryItemAdded
            property={property}
            key={property.propertyName}
          />
        );
      case AnomalyHistoryStatus.UPDATED:
        return (
          <TeiaAnomalyHistoryItemUpdated
            property={property}
            key={property.propertyName}
          />
        );
      case AnomalyHistoryStatus.DELETED:
        return (
          <TeiaAnomalyHistoryItemDeleted
            property={property}
            key={property.propertyName}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Text bold>{anomalyHistory.author}</Text>
        <Text>{new Date(anomalyHistory.creationDate).toLocaleString('fr-FR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })}
        </Text>
      </Stack>
      {anomalyHistory.anomalyHistoryState !== AnomalyHistoryStatus.CREATED &&
        anomalyHistory.anomalyHistoryPropertyDtos.map((property) => (
          renderAnomalyItemByState(anomalyHistory.anomalyHistoryState, property)
        ))
      }
      {anomalyHistory.anomalyHistoryState === AnomalyHistoryStatus.CREATED && (
        <TeiaAnomalyHistoryItemCreated />
      )}
    </>
  );
};
