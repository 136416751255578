import { Box, Table, TableContainer } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

export interface BlurTableProps {
  children?: ReactNode;
  blurSize?: string;
  detectionZoneSize?: number;
}

/**
 * Renders a MUITable. If the table is larger than its parent, makes it
 * scrollable and renders blur bars on the left/right side when needed
 * @param props
 * @returns
 */
export const BlurTable = (props: BlurTableProps) => {
  const { children, blurSize = '30px', detectionZoneSize = 10 } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const [blurStatus, setBlurStatus] = useState({ blurLeft: false, blurRight: false });

  useEffect(() => {
    updateBlurStatus();
  }, []);

  const onScroll = () => {
    updateBlurStatus();
  };

  const updateBlurStatus = () => {
    if (ref.current) {
      setBlurStatus({
        blurLeft: ref.current.scrollLeft > detectionZoneSize,
        blurRight:
          ref.current.scrollWidth - ref.current.offsetWidth - ref.current.scrollLeft >
          detectionZoneSize
      });
    }
  };

  return (
    <Box sx={{ position: 'relative', overflowX: 'scroll', width: 1, scrollbarWidth: 'none' }}>
      <TableContainer ref={ref} onScroll={onScroll}>
        <Table>{children}</Table>
      </TableContainer>
      {blurStatus.blurLeft && (
        <Box
          sx={{
            top: 0,
            position: 'absolute',
            width: blurSize,
            height: 1,
            backgroundColor: 'red',
            background: 'linear-gradient(to left, transparent, #E3E3E3)'
          }}
        />
      )}
      {blurStatus.blurRight && (
        <Box
          sx={{
            right: 0,
            top: 0,
            position: 'absolute',
            width: blurSize,
            height: 1,
            background: 'linear-gradient(to right, transparent, #E3E3E3)'
          }}
        />
      )}
    </Box>
  );
};
