import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { Box, Icon, TextField } from '@stereograph/teia-system-design';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';
import {
  TeiaObjectSelection,
  TeiaViewer,
  TeiaViewerSourceType,
  useTeiaSource,
  useViewerContext
} from '@stereograph/teiaviewer';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as UUID } from 'uuid';
import { useTiaConversationApi } from '../../api/hooks/useTiaConversationApi';
import { AssistantResponse, ChatMessageInput } from '../../api/TiaConversationApi';
import { useAttachmentControllerRegistryContext } from '../../attachment/AttachmentControllerRegistry';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { useChatContext } from './ChatContext';
import { ChatMessage } from './ChatMessage';

export const ChatInputField = () => {
  const { viewer } = useViewerContext<TeiaViewer>();
  const inputRef = useRef<HTMLInputElement>();
  const { addMessage, conversationId, isWorking, setIsWorking } = useChatContext();
  const { usePostMessage } = useTiaConversationApi();
  const { mutateAsync: postMessage } = usePostMessage();
  const { getErrorMessage } = useErrorMessage();
  const twin = useTeiaSource(TeiaViewerSourceType.Twin)!;
  const { registry } = useAttachmentControllerRegistryContext();

  const onAssistantMsgSuccess = (response: AssistantResponse) => {
    const attachments = response.attachments;

    // Insert attachment messages into the conversation before the message from the assistant
    for (const attachment of attachments) {
      const controller = registry.getController(attachment.type);
      controller.processAttachment(attachment);

      if (controller.showInConversation) {
        addMessage({
          role: 'attachment',
          text: '',
          id: UUID(),
          attachment: attachment
        });
      }
    }

    addMessage({
      role: 'assistant',
      text: response.messageText,
      id: response.messageId,
      completionTokens: response.completionTokens,
      promptTokens: response.promptTokens,
      tools: response.usedTools,
      attachments: attachments
    });
    setIsWorking(false);
  };

  const onAssistanceMsgError = (error: Error) => {
    addMessage({
      role: 'system',
      text: getErrorMessage(error),
      id: 'system-error-message-id'
    });
    setIsWorking(false);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [isWorking]);

  const [userInput, setUserInput] = useState('');

  const onValidateInput = () => {
    if (!viewer.currentSource || 'fileId' in viewer.currentSource) {
      toast.error('You need to have a twin loaded to discuss with Elliot !');
      return;
    }
    if (userInput === '') return;

    addUserMessage({ text: userInput, role: 'user', id: UUID() });
    setUserInput('');
  };

  const getViewerSelection = () => {
    let filter: TeiaSearchFilter;
    const selection = viewer.scene.objectSelection as TeiaObjectSelection;
    if (selection.isTeiaObjectSelection && selection.filter) {
      filter = selection.filter;
    } else {
      filter = { condition: 'Or', rules: [] };
      const generator = selection.getObjects();
      for (const obj of generator) {
        filter.rules.push({ type: 'Guid', operator: 'EqualTo', value: obj.uuid });
      }
    }
    return filter;
  };

  const addUserMessage = async (message: ChatMessage) => {
    addMessage(message);

    const filter = getViewerSelection();

    const input: ChatMessageInput = {
      text: message.text,
      projectId: twin.projectId,
      viewerState: {
        currentSelection: filter
      }
    };
    setIsWorking(true);
    postMessage({ conversationId, input }).then(onAssistantMsgSuccess).catch(onAssistanceMsgError);
  };

  return (
    <Box sx={{ width: 1, display: 'flex', gap: 1 }}>
      <TextField
        aria-disabled={isWorking}
        autoFocus={true}
        tabIndex={0}
        inputRef={inputRef}
        sx={{ flexGrow: 1 }}
        placeholder="Type your message here"
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onValidateInput();
            e.preventDefault();
          }
        }}
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        disabled={isWorking}
      />
      <IconButton aria-label="Send Message" onClick={onValidateInput} color="primary">
        <Icon component={PaperAirplaneIcon} />
      </IconButton>
    </Box>
  );
};
