import { usePluginTranslation } from '@translation';

export const useErrorMessage = () => {
  const { t } = usePluginTranslation();

  const getErrorMessage = (error: Error) => {
    const status = (error.cause as Response)?.status ?? -1;

    switch (status) {
      case 401:
        return t('chatbot_plugin.errors.not_authenticated.text');
      case 402:
        return t('chatbot_plugin.errors.no_more_token.text');
      default:
        return t('chatbot_plugin.errors.unknown_error.text');
    }
  };

  return { getErrorMessage };
};
