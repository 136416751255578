import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/deprecated';
import {
  InfoBoxWidget,
  InfoBoxWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { ElliotConfigWidgetContent } from './ElliotConfigWidgetContent';

declare global {
  interface ViewerWidgetRegistry {
    ElliotConfigWidget: WidgetFactoryFunction<ElliotConfigWidget>;
  }
}

export class ElliotConfigWidget extends InfoBoxWidget {
  override readonly type = 'ElliotConfigWidget';
  override readonly icon = { heroIcon: WrenchScrewdriverIcon };
  readonly isElliotConfigWidget = true;

  constructor(
    override viewer: TeiaViewer,
    options: InfoBoxWidgetOptions = {}
  ) {
    super(viewer, { ...options, color: 'secondary' });
    this.setUuids(['']); // In order to show the content of the tabs, we need to set the uuids to trigger the rendering
  }

  override Header = () => {
    return (
      <header className="st-flex st-gap-1 st-items-center">
        <Icon icon={this.icon} size="xs" />
        <p>Elliot Config</p>
      </header>
    );
  };

  override Content = () => {
    return <ElliotConfigWidgetContent />;
  };

  override Footer = () => {
    return <></>;
  };
}
