import { Alert, AlertTitle, Box, LinearProgress, Text } from '@stereograph/teia-system-design';
import { useTiaQuotaApi } from '../../api/hooks/useTiaQuotaApi';
import { Quota } from '../../api/TiaQuotaApi';

export const TokenInfoView = () => {
  const { useGetQuota } = useTiaQuotaApi();
  const { data: quota, isLoading } = useGetQuota();

  const getProgressValue = (quota: Quota) => {
    if (quota.totalToken == 0) return 0;
    return Math.round(Math.min(100, quota.tokenConsumption / quota.totalToken));
  };

  const isError = !isLoading && !quota;

  return (
    <Alert sx={{ '& .MuiAlert-message': { width: 1 } }} severity={isError ? 'error' : 'info'}>
      <AlertTitle>Tokens status</AlertTitle>
      {isLoading && <LinearProgress variant="indeterminate" />}
      {isError && <Text>No tokens available for your instance.</Text>}
      {!isLoading && quota && (
        <Box sx={{ display: 'flex', alignItems: 'center', width: 1 }}>
          <Box sx={{ flexGrow: 1 }}>
            <LinearProgress variant="determinate" color="primary" value={getProgressValue(quota)} />
          </Box>
          <Text hidden={isLoading} sx={{ mx: 5 }}>
            {quota.tokenConsumption.toLocaleString()} / {quota.totalToken.toLocaleString()} (
            {getProgressValue(quota)}%)
          </Text>
        </Box>
      )}
    </Alert>
  );
};
