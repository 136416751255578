import { PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { ShowTeiaPatchDataWidget } from './ToolbarItems';
import { TeiaPatchDataWidget } from './widgets/TeiaPatchDataWidget';

declare global {
  interface ViewerPluginRegistry {
    patchData: PatchDataPlugin;
  }
}

const PluginBaseClass = PluginBuilder.NewPlugin('PatchDataPlugin');

export class PatchDataPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = { ShowTeiaPatchDataWidget };

  override onRegister(): void {
    setupPluginsTranslation();
    this._setupWidgets();
  }

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('TeiaPatchData', ({ viewportRect }) => {
      const { width, height } = viewportRect;
      return new TeiaPatchDataWidget(this.viewer, {
        x: width - width / 3 - 5,
        y: 5,
        width: width / 3,
        height: height - 10,
        minWidth: 400,
        minHeight: 400
      });
    });
  }
}
