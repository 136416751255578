import { PlusIcon } from '@heroicons/react/24/outline';
import { Alert, Icon, IconButton, Stack } from '@stereograph/teia-system-design';
import { OpenDataAction } from '@api/interfaces/OpenData';
import { usePluginTranslation } from '@translation';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { OpenDataSchema } from '../widgets/TeiaOpenDataForm';
import { OpenDataActionField } from './OpenDataActionField';

export const OpenDataActionFieldList = () => {
  const { t } = usePluginTranslation();
  const { control } = useFormContext<OpenDataSchema>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'propertySchema'
  });

  return (
    <Stack spacing={4}>
      <Stack spacing={8}>
        {fields.map((field, index) => (
          <OpenDataActionField index={index} removeField={remove} key={field.id} />
        ))}
      </Stack>
      {fields.length === 0 && (
        <Alert severity="info">
          <Trans t={t} i18nKey="open_data_plugin.no_fields.text" components={{ 1: <br /> }} />
        </Alert>
      )}
      <Stack alignItems="center" justifyContent="center">
        <IconButton
          aria-label={t('open_data_plugin.form.property.add_property_label')}
          onClick={() =>
            append({
              action: OpenDataAction.Add,
              key: '',
              value: ''
            })
          }
        >
          <Icon component={PlusIcon} />
        </IconButton>
      </Stack>
    </Stack>
  );
};
