import { ApiClient } from '@stereograph/teia-system-design/apis';
import { ViewerCommand } from '../interface/ViewerCommand';

export interface FeedbackResponse {
  message: string;
}

export type MessageFeedback = 'positive' | 'negative';

export interface CompletionResponse {
  conversationId: number;
  messageId: number;
  message: string;
  finishReason: string;
  viewerCommands: Array<ViewerCommand>;
}

export interface CompletionInput {
  message: string;
  projectId: number;
  selectedGuids: ReadonlyArray<string>;
}

export const TiaConversationApi = (client: ApiClient) => ({
  /**
   *
   * @param conversationId
   * @param messageId
   * @param input
   * @returns
   */
  postFeedback(conversationId: number, messageId: number, feedback: MessageFeedback) {
    const url = client.URL(`feedback/${conversationId}/${messageId}`);
    const data = JSON.stringify({ feedback });

    const request = new Request(url, {
      method: 'POST',
      body: data
    });

    return client.sendRequest<FeedbackResponse>(request);
  },

  /**
   *
   * @param conversationId
   * @param input
   * @returns
   */
  postMessage(conversationId: number, input: CompletionInput) {
    let url;
    if (conversationId === 0) {
      url = client.URL('messages');
    } else {
      url = client.URL(`messages/${conversationId}`);
    }
    const data = JSON.stringify(input);

    const request = new Request(url, {
      method: 'POST',
      body: data
    });

    return client.sendRequest<CompletionResponse>(request);
  }
});
