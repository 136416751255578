import { SelectionFilter } from '@api/interfaces/SelectionFilter';
import { Button, Menu, Stack, Text } from '@stereograph/teia-system-design';
import {
  TeiaSearchFilter,
  TeiaSearchGroup,
  TeiaSearchRule
} from '@stereograph/teia-system-design/apis';
import { usePluginTranslation } from '@translation';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useAllSelectionFilters } from '../../hooks/useSelectionFilter';
import { useTeiaQuantityTakeoffContext } from '../TeiaQuantityTakeoffProvider';
import { TeiaQuantityTakeoffSelectionList } from './TeiaQuantityTakeoffSelectionList';

interface TeiaQuantityTakeoffAdvancedSearchProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
}

export interface SelectionForm {
  selections: Array<string>;
}

const computeSearch = (selections: Array<SelectionFilter>) => {
  return {
    condition: 'Or',
    rules: [...selections.map((selection) => selection.filter as TeiaSearchRule)]
  } as TeiaSearchRule;
};

const getSelectionsByIds = (ids: Array<string>, selections: Array<SelectionFilter>) => {
  return ids
    .map((id) => selections.find((selection) => String(selection.id) === id))
    .filter((selection): selection is SelectionFilter => selection !== undefined);
};

export const TeiaQuantityTakeoffSelection = (props: TeiaQuantityTakeoffAdvancedSearchProps) => {
  const { anchorEl, setAnchorEl } = props;
  const { updateSearchRules } = useTeiaQuantityTakeoffContext();
  const selections = useAllSelectionFilters();
  const { t } = usePluginTranslation();
  const { handleSubmit, reset, ...formMethods } = useForm<SelectionForm>({
    defaultValues: {
      selections: []
    }
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateSearchRules = (filter: TeiaSearchFilter) => {
    if ('condition' in filter && 'rules' in filter) {
      updateSearchRules(filter as TeiaSearchGroup);
      handleClose();
    }
  };

  const onSubmit: SubmitHandler<SelectionForm> = (data) => {
    const selectedSelectionFilter = getSelectionsByIds(data.selections, selections ?? []);
    handleUpdateSearchRules(computeSearch(selectedSelectionFilter));
  };

  const selectedSelections = formMethods.watch('selections').length;

  return (
    <Menu
      id={'qto-advanced-search'}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      slotProps={{
        paper: {
          sx: {
            width: '600px',
            px: 2
          }
        }
      }}
    >
      <Stack spacing={2}>
        <FormProvider {...formMethods} handleSubmit={handleSubmit} reset={reset}>
          <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} spacing={2}>
            <TeiaQuantityTakeoffSelectionList />
            <Stack
              direction={'row'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{ width: '100%' }}
            >
              <Text variant={'body2'}>
                {t('quantity_takeoff_plugin.load_from_selection.selected_selections.label', {
                  count: selectedSelections
                })}
              </Text>
              <Stack direction={'row'} spacing={1}>
                <Button onClick={() => reset()} variant={'text'} color={'info'}>
                  {t('quantity_takeoff_plugin.reset.label')}
                </Button>
                <Button
                  type={'submit'}
                  sx={{
                    alignSelf: 'end'
                  }}
                >
                  {t('quantity_takeoff_plugin.refresh_data_button_label')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      </Stack>
    </Menu>
  );
};
