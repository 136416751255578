import { ApiClient, ApiDataList, TeiaSearchFilter } from '@stereograph/teia-system-design/apis';
import { TwinType } from '@api/interfaces/Twin';

type Search2Response = ApiDataList<Array<{
  id: number,
  guid: string,
  name: string,
  parentId: number,
  parentGuid: string,
  type: string,
  teiaType: string
}>>;

export const TwinObjectApi = (client: ApiClient) => ({
  search2({ projectId, type = 'Draft', pageNumber = 1, pageSize = 100, filter }: {
    projectId: number,
    type: TwinType,
    pageNumber?: number,
    pageSize?: number,
    filter: TeiaSearchFilter
  }) {
    const url = client.URL(`projects/${projectId}/twin/objects/search2`);
    url.searchParams.set('twinProjectType', type);
    url.searchParams.set('pageNumber', String(pageNumber));
    url.searchParams.set('pageSize', String(pageSize));
    const body = JSON.stringify(filter);
    const request = new Request(url, {
      method: 'POST',
      body
    });
    return client.sendRequest<Search2Response>(request);
  }
});