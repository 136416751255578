import { Stack } from '@stereograph/teia-system-design';
import { TeiaAnomalyHistoryChip } from './TeiaAnomalyHistoryChip';

interface TeiaAnomalyHistoryItemBaseProps {
  chipColor: 'error' | 'warning' | 'primary';
  chipLabel: string;
  children?: React.ReactNode;
}

export const TeiaAnomalyHistoryItemBase = (props: TeiaAnomalyHistoryItemBaseProps) => {
  const { chipColor, chipLabel, children } = props;

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TeiaAnomalyHistoryChip color={chipColor} label={chipLabel} />
      {children}
    </Stack>
  );
};
