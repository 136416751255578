import { PluginBuilder, TeiaViewer } from '@stereograph/teiaviewer';
import { setupPluginsTranslation } from '@translation';
import { ShowTeiaAnomalyMenu } from './ToolbarItems';
import { TeiaAnomalyResumeWidget } from './widgets/TeiaAnomalyResumeWidget';
import { TeiaCreateAnomalyWidget } from './widgets/TeiaCreateAnomalyWidget';
import { TeiaUpdateAnomalyWidget } from './widgets/TeiaUpdateAnomalyWidget';

declare global {
  interface ViewerPluginRegistry {
    anomaly: TeiaAnomalyPlugin;
  }
}

const PluginBaseClass = PluginBuilder.NewPlugin('TeiaAnomalyPlugin');

export class TeiaAnomalyPlugin extends PluginBaseClass<TeiaViewer> {
  static override readonly ToolbarItems = {
    ShowTeiaAnomaly: ShowTeiaAnomalyMenu
  };

  override onRegister(): void {
    setupPluginsTranslation();
    this._setupWidgets();
  }

  private _setupWidgets() {
    this.viewer.widgetFactory.registerWidget('TeiaCreateAnomaly', ({ viewportRect, projectId }) => {
      const { width, height } = viewportRect;
      return new TeiaCreateAnomalyWidget(this.viewer, projectId, {
        x: (width - 900) / 2,
        y: (height - 600) / 2,
        width: 700,
        height: 450,
        minWidth: 600,
        minHeight: 400
      });
    });

    this.viewer.widgetFactory.registerWidget(
      'TeiaUpdateAnomaly',
      ({ viewportRect, projectId, anomalyId }) => {
        const { width, height } = viewportRect;
        return new TeiaUpdateAnomalyWidget(this.viewer, projectId, anomalyId, {
          x: (width - 900) / 2,
          y: (height - 600) / 2,
          width: 700,
          height: 550,
          minWidth: 600,
          minHeight: 450
        });
      }
    );

    this.viewer.widgetFactory.registerWidget('TeiaAnomalyResume', ({ viewportRect, projectId }) => {
      const { width, height } = viewportRect;
      return new TeiaAnomalyResumeWidget(this.viewer, projectId, {
        x: 5,
        y: 5,
        width: width / 3,
        height: height - 10,
        minWidth: 800,
        minHeight: height - height / 3
      });
    });
  }
}
