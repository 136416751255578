import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';
import { Icon } from '@stereograph/teia-system-design/deprecated';
import {
  StandardWidget,
  StandardWidgetOptions,
  TeiaViewer,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { ChatBotWidgetContent } from './ChatBotWidgetContent';
import { ConversationProvider } from './ConversationContext';

declare global {
  interface ViewerWidgetRegistry {
    ChatBotWidget: WidgetFactoryFunction<ChatBotWidget>;
  }
}

export class ChatBotWidget extends StandardWidget<TeiaViewer> {
  readonly type = 'ChatBotWidget';
  readonly icon = { heroIcon: ChatBubbleLeftIcon };
  readonly isChatBotWidget = true;

  constructor(viewer: TeiaViewer, options: StandardWidgetOptions = {}) {
    super(viewer, { ...options, color: 'secondary', useFooter: false });
  }

  override Header = () => {
    return (
      <div className="st-flex st-gap-1 st-items-center">
        <Icon icon={this.icon} size="xs" />
        <p>Elliot (Alpha)</p>
      </div>
    );
  };

  override Content = ChatBotWidgetContent;

  override ContextProvider = ConversationProvider;
}
