/* eslint-disable @stylistic/quotes */

export enum PrePromptEnum {
  ABREVIATION,
  DATA_SHORTCUT,
  NONE
}

export class BasePrompts {
  private static readonly prePromptMap = new Map<PrePromptEnum, string>([
    [
      PrePromptEnum.ABREVIATION,
      "Les valeurs suivantes organisées en 'clef-valeur' sont des abréviations. Quand l'utisateur utilise la clef, vous la remplacerez par la valeur pour faire vos requetes."
    ],
    [
      PrePromptEnum.DATA_SHORTCUT,
      "Les valeurs suivantes organisées en 'clef-valeur' sont des indications sur là où trouver la donnée. Quand l'utisateur mentionne le concept dans clef, vous la remplacerez par la valeur pour faire vos requetes."
    ]
  ]);

  public static getPrePromptTemplate(prePromptEnum: PrePromptEnum): string {
    return BasePrompts.prePromptMap.get(prePromptEnum) || '';
  }
}
