export interface OpenDataProperty {
  key: string;
  value: string;
  action: OpenDataAction;
}

export interface OpenDataRequest {
  name: string;
  twinObjectGuids: Array<string>;
  properties: Array<OpenDataProperty>;
}

export interface OpenDataResponse {
  id: number;
}

export enum OpenDataAction {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
  AddOrUpdate = 'AddOrUpdate'
}

export interface OpenDataKeysResponse {
  keys: Array<string>;
  afterKey?: string;
}