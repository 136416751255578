import { Box } from '@stereograph/teia-system-design';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTiaConversationApi } from '../../../api/hooks/useTiaConversationApi';
import { MessageFeedback } from '../../../api/TiaConversationApi';
import { useErrorMessage } from '../../../hooks/useErrorMessage';
import { AssistantMessage } from '../ChatMessage';
import { MessageFeedbackControl } from '../common/MessageFeedbackControl';
import { MessageHeader } from '../common/MessageHeader';
import { MessageInfoPanel } from '../common/MessageInfoPanel';
import { MessageText } from '../common/MessageText';
import { MessageView } from '../common/MessageView';

interface AssistantMessageViewProps {
  message: AssistantMessage;
  conversationId: string;
  isLastMessage: boolean;
  isFirstMessage: boolean;
}

export const AssistantMessageView = (props: AssistantMessageViewProps) => {
  const { message, conversationId, isLastMessage, isFirstMessage } = props;
  const [feedback, setFeedback] = useState<MessageFeedback | null>(null);
  const messageId = message.id;
  const { usePostFeedback } = useTiaConversationApi();
  const { mutateAsync, isPending: isPostingFeedback } = usePostFeedback();
  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (!feedback) {
      return;
    }
    mutateAsync(
      { conversationId, messageId, feedback },
      {
        onError: (error) => {
          setFeedback(null);
          toast.error(getErrorMessage(error));
        }
      }
    );
  }, [feedback]);

  return (
    <MessageView color="info.light">
      <MessageHeader title="🤖 Elliot">
        {!isFirstMessage && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <MessageFeedbackControl
              value={feedback}
              onChange={setFeedback}
              isLoading={isPostingFeedback}
            />
            <MessageInfoPanel message={message} />
          </Box>
        )}
      </MessageHeader>
      <MessageText content={message.text} animated={isLastMessage} />
    </MessageView>
  );
};
