import { usePluginTranslation } from '@translation';
import { TeiaAnomalyHistoryItemBase } from './TeiaAnomalyHistoryItemBase';

export const TeiaAnomalyHistoryItemCreated = () => {
  const { t } = usePluginTranslation();

  return (
    <TeiaAnomalyHistoryItemBase
      chipColor="primary"
      chipLabel={t('anomaly_plugin.widget.history.created.label')}
    >
    </TeiaAnomalyHistoryItemBase>
  );
};
