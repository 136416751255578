import { AnomalyHistoryProperty } from '@api/interfaces/Anomaly';
import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Icon, Text } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { TeiaAnomalyHistoryItemBase } from './TeiaAnomalyHistoryItemBase';

interface TeiaAnomalyHistoryItemDeletedProps {
  property: AnomalyHistoryProperty,
}

export const TeiaAnomalyHistoryItemDeleted = (props: TeiaAnomalyHistoryItemDeletedProps) => {
  const { property } = props;
  const { t } = usePluginTranslation();

  return (
    <TeiaAnomalyHistoryItemBase
      chipColor="error"
      chipLabel={t('anomaly_plugin.widget.history.deleted.label')}
    >
      <Text bold>{property.propertyName}</Text>
      <Icon component={ArrowLongRightIcon} sx={{ width: 15 }} />
      <Text>{property.oldValue}</Text>
    </TeiaAnomalyHistoryItemBase>
  );
};
