
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CommentApi } from '../CommentApi';
import {
  CommentQueryParams,
  RequestPostComment
} from '../interfaces/Comment';
import { commentQueryKeys } from '../queryKeys/comment.query-keys';

export const usePostComment = (projectId: number) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const { mutateAsync: postComment } = useMutation({
    mutationFn: (
      params: { requestPostComment: RequestPostComment }
    ) => CommentApi(client, projectId).postComment(params.requestPostComment),
    onSuccess: (_response, variables) => {
      const commentQueryParams = {
        targetId: variables.requestPostComment.targetId,
        targetType: variables.requestPostComment.targetType,
      } as CommentQueryParams;

      queryClient.invalidateQueries({
        queryKey: commentQueryKeys.getAll(projectId, commentQueryParams),
      });
    },
  });

  return {
    postComment,
  };
};
