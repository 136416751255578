import { SelectionFilter } from '@api/interfaces/SelectionFilter';
import { MRT_Row } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';

interface SectionVisibilityCellProps {
  row: MRT_Row<SelectionFilter>;
}

export const SectionVisibilityCell = (props: SectionVisibilityCellProps) => {
  const { row } = props;
  const { t } = usePluginTranslation();
  return row.original.isPublic
    ? t('selection_plugin.record_widget.is_public.cell.public.text')
    : t('selection_plugin.record_widget.is_public.cell.private.text');
};
