import { Comment } from '@api/interfaces/Comment';
import { Stack, Text } from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';

interface CommentProps {
  comment: Comment;
}

export const CommentHeading = (props: CommentProps) => {
  const { comment } = props;
  const { t } = usePluginTranslation();

  return (
    <Stack direction="row" spacing={1}>
      <Text className="st-text-md st-font-semibold">{`${comment.username}`}</Text>
      <Text>
        {t('date', {
          val: new Date(comment.creationDate),
          formatParams: {
            val: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: 'numeric',
              minute: 'numeric'
            }
          }
        })}
      </Text>
    </Stack>
  );
};
