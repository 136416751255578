/* eslint-disable @stylistic/quotes */

import { PrePromptEnum } from './BasePrompts';

export class CSVExamples {
  private static readonly titleMap = new Map<PrePromptEnum, string>([
    [PrePromptEnum.ABREVIATION, 'abreviation_example'],
    [PrePromptEnum.DATA_SHORTCUT, 'data_shortcut_example']
  ]);

  private static readonly contentMap = new Map<PrePromptEnum, string>([
    [
      PrePromptEnum.ABREVIATION,
      "abreviation,sens de l'abreviation\r\nPVC,Chlorure de polyvinyle\r\nPEHD,Polyéthylène haute densité\r\nPP,Polypropylène\r\nPS,Polystyrène"
    ],
    [
      PrePromptEnum.DATA_SHORTCUT,
      'concept/sujet,source de la donnée\r\nla pression,donnée "pression" dans userdata\r\nla température,donnée "temperature" dans userdata.'
    ]
  ]);

  public static getTitle(prePromptEnum: PrePromptEnum): string {
    return CSVExamples.titleMap.get(prePromptEnum) || '';
  }

  public static getContent(prePromptEnum: PrePromptEnum): string {
    return CSVExamples.contentMap.get(prePromptEnum) || '';
  }
}
