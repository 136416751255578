import { ApiClient } from '@stereograph/teia-system-design/apis';

export interface Quota {
  tokenConsumption: number;
  totalToken: number;
}

export const TiaQuotaApi = (client: ApiClient) => ({
  getQuota() {
    const url = client.URL('quota');

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Quota>(request);
  }
});
