export const dissociateImportedFile = (files: FileList) => {
  return Array.from(files).reduce(
    (acc, file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();

      if (fileExtension === 'bcf' || fileExtension === 'bcfzip') {
        acc.bcfFiles.push(file);
      } else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
        acc.excelFiles.push(file);
      }

      return acc;
    },
    { bcfFiles: [] as Array<File>, excelFiles: [] as Array<File> }
  );
};
