import { CommentQueryParams } from '@api/interfaces/Comment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Text
} from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useAnomalyContext } from '../context/AnomalyContext';
import { useGetAnomalyComments } from '../hooks/useGetAnomalyComments';
import { CreateAnomalyCommentForm } from './CreateAnomalyCommentForm';
import { TeiaAnomalyCommentList } from './TeiaAnomalyCommentList';

interface TeiaAnomalyCommentAccordionProps {
  anomalyId: number;
}

export const TeiaAnomalyCommentAccordion = (props: TeiaAnomalyCommentAccordionProps) => {
  const { anomalyId } = props;
  const { projectId } = useAnomalyContext();
  const { t } = usePluginTranslation();
  const { getAnomalyComments } = useGetAnomalyComments(projectId);

  const commentQueryParams: CommentQueryParams = {
    pageNumber: 1,
    pageSize: 100,
    targetId: anomalyId,
    targetType: 'Anomaly',
  };

  const comments = getAnomalyComments(commentQueryParams);

  if (!comments) {
    return null;
  }


  return (
    <Box key="anomaly-history-accordion">
      <Accordion color="info">
        <AccordionSummary>
          <Text variant="lead">{t('anomaly_plugin.widget.comment_form.comment_title')}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ padding: 4 }}>
            <TeiaAnomalyCommentList comments={comments} anomalyId={anomalyId} />
            <CreateAnomalyCommentForm anomalyId={anomalyId} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
