import { PatchDataAction, PatchDataPostRequest } from '@api/interfaces/PatchData';
import { Twin } from '@api/interfaces/Twin';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Stack, Textarea, TextField } from '@stereograph/teia-system-design';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';
import { ObjectSelection, useWidgetContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import i18next from 'i18next';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as z from 'zod';
import { usePatchData } from '../api/usePatchDataApi';
import { PatchDataActionFieldList } from '../components/PatchDataActionFieldList';

const patchDataPropertySchema = z
  .object({
    propertyName: z.string().trim().min(1),
    name: z.string().trim().min(1),
    value: z.string().trim().min(1).optional(),
    action: z.nativeEnum(PatchDataAction)
  })
  .refine((schema) => {
    if (schema.action === PatchDataAction.Delete) {
      return schema.value === undefined;
    }
    return true;
  });

const patchDataSchema = z.object({
  name: z.string().trim().min(1),
  description: z.string().trim().min(1),
  image: z.string().base64().min(1),
  twinObjectGuids: z.array(z.string().trim().min(1)).nonempty(),
  properties: z.array(patchDataPropertySchema).nonempty()
});

const patchDataFormSchema = z.object({
  patchDataSchema
});

export type PatchDataSchema = z.infer<typeof patchDataFormSchema>;

export interface TeiaPatchDataWidgetFormProps {
  image: string;
  twin: Twin;
  selection: ObjectSelection;
  filter: TeiaSearchFilter;
}

export const TeiaPatchDataWidgetForm = (props: TeiaPatchDataWidgetFormProps) => {
  const { image, selection, filter, twin } = props;
  const { widget } = useWidgetContext();
  const { submitPatchData } = usePatchData(twin);
  const { t } = usePluginTranslation();
  const defaultPatchDataName = getDefaultInputName(`${t('patch_data_plugin.text')}`);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<PatchDataSchema>({
    resolver: zodResolver(patchDataFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      patchDataSchema: {
        name: defaultPatchDataName,
        twinObjectGuids: Array.from(selection.getUuids()),
        description: '',
        image: image,
        properties: [
          {
            action: PatchDataAction.Create,
            name: '',
            propertyName: '',
            value: ''
          }
        ]
      }
    }
  });
  const { handleSubmit, control } = methods;

  const onSubmit = async (data: PatchDataSchema) => {
    const patchDataRequest: PatchDataPostRequest = { ...data.patchDataSchema };

    setIsSubmitting(true);
    toast
      .promise(submitPatchData({ patchDataRequest }), {
        loading: t('patch_data_plugin.form.submit.loading.text'),
        success: () => {
          onCloseWidget();
          return t('patch_data_plugin.form.submit.success.text');
        },
        error: t('patch_data_plugin.form.submit.error.text')
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onCloseWidget = () => {
    widget.close();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="st-w-full">
        <Stack spacing={4}>
          <Controller
            control={control}
            name="patchDataSchema.name"
            render={({ field, fieldState: { error } }) => (
              <TextField
                id={field.name}
                label={t('patch_data_plugin.form.name.label')}
                placeholder={t('patch_data_plugin.form.name.placeholder')}
                error={error !== undefined}
                helperText={error?.message}
                required
                onChange={field.onChange}
                name={field.name}
                value={field.value}
              />
            )}
          />
          <Controller
            control={control}
            name="patchDataSchema.description"
            render={({ field, fieldState: { error } }) => {
              return (
                <Textarea
                  sx={{ minWidth: 200 }}
                  id={field.name}
                  placeholder={t('patch_data_plugin.form.description.placeholder')}
                  label={t('patch_data_plugin.form.description.label')}
                  error={error !== undefined}
                  helperText={error?.message}
                  required
                  onChange={field.onChange}
                  name={field.name}
                  value={field.value}
                />
              );
            }}
          />
        </Stack>
        <Box
          sx={{
            marginY: 4
          }}
        >
          <PatchDataActionFieldList filter={filter} />
        </Box>
        <Stack direction="row" spacing={4} justifyContent="end">
          <Button type="button" color="info" onClick={() => onCloseWidget()}>
            {t('patch_data_plugin.form.cancel_button.label')}
          </Button>
          <Button type="submit" color="primary" disabled={isSubmitting}>
            {t('patch_data_plugin.form.validate_button.label')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

const getDefaultInputName = (label: string): string => {
  const date = new Date();
  let formattedDate = date.toLocaleDateString(i18next.language, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit'
  });
  formattedDate = formattedDate.replace(/\//g, '-');
  return `${formattedDate} ${label} ${date.getTime()}`;
};
