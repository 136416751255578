import { Anomaly } from '@api/interfaces/Anomaly';
import { MRT_Row, Stack } from '@stereograph/teia-system-design';
import { EditAnomalyButton } from './EditAnomalyButton';
import { VisualizeSelectionButton } from './VisualizeSelectionButton';

interface ActionsCellProps {
  row: MRT_Row<Anomaly>;
}

export const ActionsCell = (props: ActionsCellProps) => {
  const { row } = props;

  return (
    <Stack direction="row" alignItems={'center'} spacing={2}>
      <EditAnomalyButton anomalyId={row.original.id} />
      <VisualizeSelectionButton anomaly={row.original} />
    </Stack>
  );
};
