import { Box } from '@stereograph/teia-system-design';
import { ReactNode } from 'react';

export interface MessageViewProps {
  children: ReactNode;
  color: string;
  alignment?: 'left' | 'right';
}

export const MessageView = (props: MessageViewProps) => {
  const { children, color, alignment = 'left' } = props;

  return (
    <Box
      sx={{
        p: 1,
        borderRadius: 3,
        backgroundColor: color,
        marginLeft: alignment === 'left' ? 0 : 'auto',
        maxWidth: '70%',
        minWidth: 0
      }}
    >
      {children}
    </Box>
  );
};
