import { ApiClient } from '@stereograph/teia-system-design/apis';
import { OpenDataKeysResponse, OpenDataProperty, OpenDataRequest, OpenDataResponse } from './interfaces/OpenData';
import { TwinType } from './interfaces/Twin';

export const OpenDataApi = (client: ApiClient) => ({
  postOpenData(projectId: number, twinType: TwinType, openData: OpenDataRequest) {
    const url = client.URL(`projects/${projectId}/opendata`);
    url.searchParams.set('twinProjectType', twinType);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(openData)
    });

    return client.sendRequest<OpenDataResponse>(request);
  },

  getOpenData(projectId: number, twinType: TwinType, twinObjectGuid: string) {
    const url = client.URL(`projects/${projectId}/twin/opendata/${twinObjectGuid}`);
    url.searchParams.set('twinProjectType', twinType);
    url.searchParams.set('twinObjectGuid', twinObjectGuid);

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Array<OpenDataProperty>>(request);
  },

  getOpenDataKeys(projectId: number, twinType: TwinType, afterKey?: string) {
    const url = client.URL(`projects/${projectId}/twin/opendata/keys`);
    url.searchParams.set('twinProjectType', twinType);
    if (afterKey) {
      url.searchParams.set('afterKey', afterKey);
    }

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify({})
    });

    return client.sendRequest<OpenDataKeysResponse>(request);
  }
});
