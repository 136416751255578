import { InfoBoxTabContainer } from '@stereograph/teiaviewer';
import { ElliotConfigTabs } from './ElliotConfigTabs';

export const ElliotConfigWidgetContent = () => {
  const tabs = [];

  tabs.push(ElliotConfigTabs.PrePromptConfig({}));
  tabs.push(ElliotConfigTabs.PrePromptTemplates({}));

  return <InfoBoxTabContainer tabs={tabs} />;
};
