import { Button, Popover, Stack } from '@stereograph/teia-system-design';
import { TeiaSearchFilterEditor } from '@stereograph/teia-system-design/teia-components';
import { TeiaSearchFilter, TeiaSearchGroup } from '@stereograph/teia-system-design/apis';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useCallback, useState } from 'react';
import { useTeiaQuantityTakeoffContext } from '../TeiaQuantityTakeoffProvider';

interface TeiaQuantityTakeoffAdvancedSearchProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
}

interface FilterState {
  filter: TeiaSearchFilter;
  isValid: boolean;
  showErrors: boolean;
}

export const TeiaQuantityTakeoffAdvancedSearch = (
  props: TeiaQuantityTakeoffAdvancedSearchProps
) => {
  const { anchorEl, setAnchorEl } = props;
  const { updateSearchRules } = useTeiaQuantityTakeoffContext();
  const { t } = usePluginTranslation();
  const twin = useTwinSource();
  const [filterState, setFilterState] = useState<FilterState>({
    filter: {},
    isValid: false,
    showErrors: false
  });

  const onChange = useCallback(
    (filter: TeiaSearchFilter, isValid: boolean) => {
      setFilterState({ filter, isValid, showErrors: !isValid });
    },
    [setFilterState]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateSearchRules = (filter: TeiaSearchFilter) => {
    if ('condition' in filter && 'rules' in filter) {
      updateSearchRules(filter as TeiaSearchGroup);
      handleClose();
    }
  };

  return (
    <Popover
      id={'qto-advanced-search'}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      slotProps={{
        paper: {
          sx: {
            minWidth: '850px',
            maxWidth: '1100px',
            width: '100%',
            p: 2
          }
        }
      }}
    >
      <Stack spacing={2}>
        <TeiaSearchFilterEditor
          twin={twin}
          defaultFilter={filterState.filter}
          onChange={onChange}
          showErrors={false}
        />
        <Button
          onClick={() => handleUpdateSearchRules(filterState.filter)}
          sx={{
            alignSelf: 'end'
          }}
        >
          {t('quantity_takeoff_plugin.refresh_data_button_label')}
        </Button>
      </Stack>
    </Popover>
  );
};
