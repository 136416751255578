export enum ObjectAnomalyInformation {
  isVisible = 'isVisible',
  isSelected = 'isSelected',
  isHidden = 'isHidden'
}

export type AnomalyGuids = Record<string, ObjectAnomalyInformation>;

export interface AnomalyScene {
  visible: Array<string>;
  hidden: Array<string>;
  selected: Array<string>;
  defaultVisibility: ObjectAnomalyInformation;
}

export enum AnomalyType {
  Clash = 'Clash',
  Inquiry = 'Inquiry',
  Issue = 'Issue',
  Fault = 'Fault',
  Remark = 'Remark',
  Request = 'Request',
  Unknown = 'Unknown'
}

export interface AnomalyTypeOption {
  value: AnomalyType;
  label: string;
}

export enum AnomalyPriority {
  Critical = 'Critical',
  Major = 'Major',
  Normal = 'Normal',
  Minor = 'Minor',
  OnHold = 'OnHold',
  Unknown = 'Unknown'
}

export interface AnomalyPriorityOption {
  value: AnomalyPriority;
  label: string;
}

export enum AnomalyStatus {
  Active = 'Active',
  InProgress = 'InProgress',
  Resolved = 'Resolved',
  Closed = 'Closed',
  ReOpened = 'ReOpened',
  Unknown = 'Unknown'
}

export interface AnomalyStatusOption {
  value: AnomalyStatus;
  label: string;
}

export enum AnomalyCameraType {
  ORTHOGONAL = 'Orthogonal',
  PERSPECTIVE = 'Perspective'
}

interface BaseAnomalyCamera {
  cameraViewPoint: { x: number; y: number; z: number };
  cameraDirection: { x: number; y: number; z: number };
  cameraUpVector: { x: number; y: number; z: number };
  aspectRatio: number;
}

export interface AnomalyPerspectiveCamera extends BaseAnomalyCamera {
  fieldOfView: number;
  type: AnomalyCameraType.PERSPECTIVE;
}

export interface AnomalyOrthographicCamera extends BaseAnomalyCamera {
  viewToWorldScale: number;
  type: AnomalyCameraType.ORTHOGONAL;
}

export type AnomalyCamera = AnomalyPerspectiveCamera | AnomalyOrthographicCamera;

export interface Anomaly {
  id: number;
  fileId: number;
  title: string;
  stateByGuid?: AnomalyGuids;
  snapshot: string; // base64url string for image
  description: string;
  dueDate: string;
  author: string;
  creationDate: string;
  updatedAuthor: string;
  updateDate: string;
  type: AnomalyType;
  priority: AnomalyPriority;
  status: AnomalyStatus;
  commentCounts: number;
  labels?: Array<string>;
  anomalyViewPointDto: AnomalyCamera;
  projectId: number;
}

export enum AnomalyHistoryStatus {
  ADDED = 'Added',
  UPDATED = 'Updated',
  DELETED = 'Deleted',
  CREATED = 'Created'
}

export interface AnomalyHistoryProperty {
  newValue: string;
  oldValue: string;
  propertyName: string;
}

export interface AnomalyHistory {
  id: number;
  anomalyId: number;
  creationDate: string;
  createdBy: string;
  author: string;
  anomalyHistoryState: AnomalyHistoryStatus;
  anomalyHistoryPropertyDtos: Array<AnomalyHistoryProperty>;
}

export interface RequestPostAnomaly {
  title: string;
  stateByGuid?: AnomalyGuids;
  snapshot: string; // base64url string for image
  description: string;
  dueDate?: string;
  type: AnomalyType;
  twinProjectType?: string;
  twinProjectId?: number;
  fileId?: number;
  priority: AnomalyPriority;
  status: AnomalyStatus;
  labels?: Array<string>;
  createAnomalyViewPointCommand: AnomalyCamera;
}

export type AnomalyOrderableFields = keyof Anomaly;

export interface GetAnomaliesQueryParams {
  pageSize: number;
  pageNumber: number;
  status?: AnomalyStatus;
  priority?: AnomalyPriority;
  type?: AnomalyType;
  fileIds?: Array<number>;
  title?: string;
  author?: string;
  isExported?: boolean;
  orderBy?: AnomalyOrderableFields;
  orderDirection?: 'asc' | 'desc';
}

export enum AnomalyFileType {
  BCF = 'bcf',
  Excel = 'xls'
}

export const ANOMALY_EXTENSIONS: Record<AnomalyFileType, string> = {
  [AnomalyFileType.BCF]: '.bcf',
  [AnomalyFileType.Excel]: '.xlsx'
};

export interface AnomalyCsvImportResponse {
  addedCount: number;
  updatedCount: number;
  failed: Record<string, string>;
}

export type OrderDirection = 'asc' | 'desc';

export interface AnomalyHistoryQueryParams {
  pageNumber: number,
  pageSize: number,
  orderDirection: OrderDirection,
  orderBy: 'creationDate' | 'id' | 'anomalyId' | 'createdBy' | 'author' | 'anomalyHistoryState'
}