interface SystemMessageProps {
  index: number;
  message: string;
}

export default function AssistantMessage(props: SystemMessageProps) {
  const { index, message } = props;
  return (
    <div
      key={index}
      className="st-w-auto st-p-1 st-rounded-md st-text-sm st-font-normal st-mr-auto st-bg-red-50 st-text-left"
      style={{ maxWidth: '85%' }}
    >
      <p className="st-flex st-h-5 st-font-thin st-pr-2">
        <span className="st-mr-1 st-text-base">🛠️</span>
        Système
      </p>
      <p>{message}</p>
    </div>
  );
}
