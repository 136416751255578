import {
  createMRTColumnHelper,
  Stack,
  Table,
  TableConfigs,
  TableProps
} from '@stereograph/teia-system-design';

import { TeiaSearchGroup } from '@stereograph/teia-system-design/apis';
import { useMemo, useState } from 'react';
import { useQuantityTakeoffColumns } from '../hooks/useQuantityTakeoffColumns';
import { useQuantityTakeoffObject } from '../hooks/useQuantityTakeoffObject';
import { TeiaQuantityTakeoffColumn, TeiaQuantityTakeoffRow } from '../types';
import { extractSearchRuleFromTableFilter, removeUnfilterableColumns } from '../utils/table-utils';
import { teiaQuantityTakeoffColumnDTO } from '../utils/teiaQuantityTakeoffColumnDTO';
import { useTeiaQuantityTakeoffContext } from './TeiaQuantityTakeoffProvider';
import { TeiaQuantityTakeoffTableCell } from './TeiaQuantityTakeoffTableCell';
import { TeiaQuantityTakeoffTableColumnError } from './TeiaQuantityTakeoffTableColumnError';
import { TeiaQuantityTakeoffTableError } from './TeiaQuantityTakeoffTableError';
import { TeiaQuantityTakeoffTableHeader } from './TeiaQuantityTakeoffTableHeader';
import { TeiaQuantityTakeoffTopToolbar } from './TeiaQuantityTakeoffTopToolbar';
import { AggregateVisibilityProvider } from './TeiaQuantityTakeoffVisibilityContext';
import { TeiaQuantityTakeoffTableInternalActions } from './toolbar/TeiaQuantityTakeoffTableInternalActions';

const defaultTableColumns = ['guid', 'name', 'parentId'];

export interface ColumnAggregateVisibility {
  count: boolean;
  min: boolean;
  max: boolean;
  sum: boolean;
  average: boolean;
}

type ColumnFilters = Array<{ id: string; value: unknown }>;

export const TeiaQuantityTakeoffTable = () => {
  const { searchRules } = useTeiaQuantityTakeoffContext();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  });
  const {
    twinColumns,
    error: columnsError,
    isLoading: isColumnsLoading
  } = useQuantityTakeoffColumns();

  const [inputColumns, setInputColumns] = useState<Array<string>>(defaultTableColumns);
  const [inputGroupBy, setInputGroupBy] = useState<TeiaQuantityTakeoffColumn | null>(null);

  const [tableColumns, setTableColumns] = useState<Array<string>>(defaultTableColumns);
  const [groupBy, setGroupBy] = useState<TeiaQuantityTakeoffColumn | null>(null);

  const [columnFilters, setColumnFilters] = useState<ColumnFilters>([]);

  const computeFilters = useMemo((): TeiaSearchGroup => {
    if (columnFilters.length) {
      return {
        condition: searchRules.condition,
        rules: [
          ...searchRules.rules,
          {
            condition: 'And',
            rules: removeUnfilterableColumns(columnFilters).map(extractSearchRuleFromTableFilter)
          } as TeiaSearchGroup
        ]
      } satisfies TeiaSearchGroup;
    }

    return searchRules;
  }, [searchRules, columnFilters]);

  const {
    data: takeOffData,
    isLoading,
    error: takeOffError
  } = useQuantityTakeoffObject(
    computeFilters,
    tableColumns,
    groupBy?.value,
    pagination.pageIndex + 1,
    pagination.pageSize
  );

  const refreshData = () => {
    setTableColumns(inputColumns);
    setGroupBy(inputGroupBy);
  };

  const displayedColumns = useMemo(() => {
    const tableColumnsSet = new Set(tableColumns);
    if (groupBy) {
      tableColumnsSet.add(groupBy.value);
    }
    return twinColumns.filter((column) => tableColumnsSet.has(column.value));
  }, [twinColumns, tableColumns, groupBy?.value]);

  const isAggregatedColumn = (column: TeiaQuantityTakeoffColumn) =>
    groupBy && groupBy.value !== column.value;

  const columnBuilder = createMRTColumnHelper<TeiaQuantityTakeoffRow>();
  const columns = useMemo(
    () =>
      displayedColumns.map((displayedColumn) => {
        return columnBuilder.accessor((row) => row[displayedColumn.value] ?? '', {
          header: displayedColumn.value,
          Header: displayedColumn.title,
          id: displayedColumn.value,
          Cell: ({ cell, renderedCellValue }) => (
            <TeiaQuantityTakeoffTableCell
              cell={cell}
              displayedColumn={displayedColumn}
              groupBy={groupBy}
              renderedCellValue={renderedCellValue}
            />
          ),
          ...(isAggregatedColumn(displayedColumn) && {
            Header: <TeiaQuantityTakeoffTableHeader displayedColumn={displayedColumn} />
          })
        });
      }),
    [displayedColumns, groupBy?.value]
  );

  const handleColumnsChange = (quantityTakeoffColumns: Array<TeiaQuantityTakeoffColumn>) => {
    setInputColumns(quantityTakeoffColumns.map((column) => column.value));
  };

  const columnOrder = [
    displayedColumns.find((column) => groupBy?.value === column.value)?.value,
    ...displayedColumns.map((column) => column.value)
  ].filter(Boolean) as Array<string>;

  const table: TableProps<TeiaQuantityTakeoffRow>['tableOptions'] = {
    data: takeOffData?.data ?? [],
    columns: columns,
    initialState: {
      density: 'compact'
    },
    state: {
      isLoading: isColumnsLoading || isLoading,
      columnOrder: columnOrder,
      pagination: pagination,
      columnFilters: columnFilters
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    manualPagination: true,
    manualFiltering: true,
    rowCount: takeOffData?.totalRecords,
    positionToolbarAlertBanner: 'none',
    enableGrouping: false,
    enableColumnResizing: true,
    columnResizeMode: 'onEnd',
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableExpandAll: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 25, 50, 100]
    },
    muiTableContainerProps: ({ table }) => ({
      sx: {
        height: `calc(100% - ${table.refs.topToolbarRef.current?.offsetHeight}px - ${table.refs.bottomToolbarRef.current?.offsetHeight}px)`,
        overflow: 'auto'
      }
    }),
    muiTablePaperProps: {
      ...TableConfigs.DefaultConfig.muiTablePaperProps,
      sx: {
        height: '100%',
        ...TableConfigs.DefaultConfig.muiTablePaperProps.sx
      }
    },
    renderTopToolbarCustomActions: () => (
      <TeiaQuantityTakeoffTopToolbar
        columns={inputColumns.map(teiaQuantityTakeoffColumnDTO)}
        columnsOptions={twinColumns}
        groupBy={inputGroupBy}
        groupByOptions={twinColumns}
        onColumnsChange={(quantityTakeoffColumns) => handleColumnsChange(quantityTakeoffColumns)}
        onGroupByChange={setInputGroupBy}
        onRefresh={refreshData}
      />
    ),
    renderToolbarInternalActions: ({ table }) =>
      TeiaQuantityTakeoffTableInternalActions({
        table: table,
        searchRules: computeFilters,
        columns: inputColumns.map(teiaQuantityTakeoffColumnDTO),
        groupBy: inputGroupBy
      })
  };

  if (columnsError) {
    return <TeiaQuantityTakeoffTableColumnError />;
  }

  return (
    <Stack
      spacing={2}
      sx={{
        flex: 1,
        overflow: 'auto'
      }}
    >
      {takeOffError && <TeiaQuantityTakeoffTableError />}
      <AggregateVisibilityProvider columns={tableColumns}>
        <Table tableOptions={table} />
      </AggregateVisibilityProvider>
    </Stack>
  );
};
