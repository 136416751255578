import { PatchDataAction } from '@api/interfaces/PatchData';
import { TrashIcon } from '@heroicons/react/24/outline';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Text,
  TextField
} from '@stereograph/teia-system-design';
import { TeiaSearchFilter } from '@stereograph/teia-system-design/apis';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTwinApi } from '../api/useTwinApi';
import { createOption, PatchDataAutoCompleteOption } from '../utils';
import { PatchDataSchema } from '../widgets/TeiaPatchDataWidgetForm';

interface PatchDataActionFieldProps {
  index: number;
  filter: TeiaSearchFilter;
  removeField: (index: number) => void;
}

export const PatchDataActionField = (props: PatchDataActionFieldProps) => {
  const { index, filter, removeField } = props;
  const { t } = usePluginTranslation();
  const twin = useTwinSource();
  const { setValue, watch, control } = useFormContext<PatchDataSchema>();
  const { useGetTwinObjectNames, useGetTwinObjectPropertyNames } = useTwinApi(twin, filter);
  const fetchPropertyNames = useGetTwinObjectPropertyNames();
  const currentPropertyName = useWatch({
    control,
    name: `patchDataSchema.properties.${index}.propertyName`
  });

  const createFilterOption = createFilterOptions<PatchDataAutoCompleteOption>();

  const actionOptions: Record<PatchDataAction, 'string'> = {
    [PatchDataAction.Create]: t('patch_data_plugin.form.action.create.text'),
    [PatchDataAction.Update]: t('patch_data_plugin.form.action.update.text'),
    [PatchDataAction.AddOrUpdate]: t('patch_data_plugin.form.action.add_or_update.text'),
    [PatchDataAction.Delete]: t('patch_data_plugin.form.action.delete.text')
  };

  const fetchNames = useGetTwinObjectNames(currentPropertyName);
  const [propertyNamesOptions, setPropertyNamesOptions] = useState<Set<string>>(new Set());
  const [namesOptions, setNamesOptions] = useState<Set<string>>(new Set());

  const onCreatePropertyNameOption = (label: string) => {
    setPropertyNamesOptions((prevPropertyNames) => new Set([...prevPropertyNames, label]));
  };

  const onCreateNamesOption = (label: string) => {
    setNamesOptions((prevNamesOptions) => new Set([...prevNamesOptions, label]));
  };

  const mergedPropertyNames = new Set([
    ...(fetchPropertyNames.data ?? []),
    ...propertyNamesOptions
  ]);
  const mergedNames = new Set([...(fetchNames.data ?? []), ...namesOptions]);

  const mergedPropertyNameOptions: Array<PatchDataAutoCompleteOption> = mergedPropertyNames.map(
    (option) => createOption(option)
  );

  const mergedNameOptions: Array<PatchDataAutoCompleteOption> = mergedNames.map((option) =>
    createOption(option)
  );

  return (
    <Stack
      sx={{
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '0.375rem',
        padding: 4
      }}
      spacing={4}
    >
      <Stack direction="row">
        <Text variant="lead">{t('patch_data_plugin.form.data.title')}</Text>
        <Text className="st-text-red-500 st-ml-0.5">*</Text>
      </Stack>

      <Stack direction="row" spacing={4}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Controller
            control={control}
            name={`patchDataSchema.properties.${index}.propertyName`}
            render={({ field }) => (
              <Autocomplete<PatchDataAutoCompleteOption, false, false, true>
                value={field.value}
                freeSolo
                clearOnBlur
                selectOnFocus
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.label && option.label !== option.value) {
                    return option.value;
                  }
                  return option.label;
                }}
                onChange={(_, value) => {
                  if (typeof value === 'object') {
                    if (value?.label && value.value) {
                      field.onChange(value.value);
                      onCreatePropertyNameOption(value.value);
                      setValue(field.name, value.value);
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = createFilterOption(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      value: inputValue,

                      label: t('patch_data_plugin.form.property.add_option_label', {
                        option: inputValue
                      })
                    });
                  }

                  return filtered;
                }}
                renderOption={(props, option) => {
                  const key = `patch-data-property-name-${window.crypto.randomUUID()}`;
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps} key={key}>
                      {option.label}
                    </li>
                  );
                }}
                id={field.name}
                options={mergedPropertyNameOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('patch_data_plugin.form.property.propertyName.placeholder')}
                  />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name={`patchDataSchema.properties.${index}.name`}
            render={({ field }) => (
              <Autocomplete<PatchDataAutoCompleteOption, false, false, true>
                value={field.value}
                freeSolo
                clearOnBlur
                selectOnFocus
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.label && option.label !== option.value) {
                    return option.value;
                  }
                  return option.label;
                }}
                onChange={(_, value) => {
                  if (typeof value === 'object') {
                    field.onChange(value?.value);
                    if (value?.label && value.value) {
                      onCreateNamesOption(value?.value);
                      setValue(field.name, value.value);
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = createFilterOption(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option.label);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      value: inputValue,

                      label: t('patch_data_plugin.form.property.add_option_label', {
                        option: inputValue
                      })
                    });
                  }

                  return filtered;
                }}
                renderOption={(props, option) => {
                  const key = `patch-data-name-${window.crypto.randomUUID()}`;
                  const { ...optionProps } = props;
                  return (
                    <li {...optionProps} key={key}>
                      {option.label}
                    </li>
                  );
                }}
                id={field.name}
                options={mergedNameOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('patch_data_plugin.form.property.name.placeholder')}
                  />
                )}
              />
            )}
          />

          {watch(`patchDataSchema.properties.${index}.action`) !== PatchDataAction.Delete && (
            <Controller
              control={control}
              name={`patchDataSchema.properties.${index}.value`}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id={field.name}
                  label={t('patch_data_plugin.form.property.value.placeholder')}
                  placeholder={t('patch_data_plugin.form.property.value.placeholder')}
                  error={error !== undefined}
                  helperText={error?.message}
                  required
                  onChange={field.onChange}
                  name={field.name}
                  value={field.value}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name={`patchDataSchema.properties.${index}.action`}
            render={({ field }) => (
              <Autocomplete<PatchDataAction, false, true>
                value={field.value}
                onChange={(_, value) => {
                  field.onChange(value);
                  if (value === PatchDataAction.Delete) {
                    setValue(`patchDataSchema.properties.${index}.value`, undefined, {
                      shouldValidate: true
                    });
                  }
                }}
                isOptionEqualToValue={(option, value) => option === value}
                disableClearable
                id={field.name}
                options={Object.keys(actionOptions) as Array<PatchDataAction>}
                getOptionLabel={(option) => actionOptions[option]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={t('patch_data_plugin.form.action.title')}
                  />
                )}
              />
            )}
          />
        </Stack>
        <Box>
          <IconButton
            aria-label={t('patch_data_plugin.form.property.delete_property_label')}
            color="error"
            onClick={() => removeField(index)}
            sx={{
              width: '36px',
              height: '36px'
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      </Stack>
    </Stack>
  );
};
