import { Comment } from '@api/interfaces/Comment';
import { TeiaAnomalyComment } from './TeiaAnomalyComment';

interface TeiaAnomalyCommentListProps {
  anomalyId: number;
  comments: Array<Comment>;
}

export const TeiaAnomalyCommentList = (props: TeiaAnomalyCommentListProps) => {
  const { comments, anomalyId } = props;

  return (
    <>
      {comments.map((comment) => (
        <TeiaAnomalyComment key={`comment-${comment.id}`} comment={comment} anomalyId={anomalyId} />
      ))}
    </>
  );
};
