interface UserMessageProps {
  index: number;
  message: string;
}

export default function UserMessage(props: UserMessageProps) {
  const { index, message } = props;
  return (
    <div
      key={index}
      className="st-w-auto st-p-1 st-rounded-md st-text-sm st-font-normal st-ml-auto st-bg-primary-25 st-text-right"
      style={{ maxWidth: '85%' }}
    >
      <p className="st-h-5 st-font-thin st-pl-2">
        Vous
        <span className="st-ml-1 st-text-base">👤</span>
      </p>
      <p>{message}</p>
    </div>
  );
}
