import { ButtonTextInput } from '@stereograph/teia-system-design/deprecated';
import { GetSelectedObjectsCommand, TeiaViewer, useViewerContext } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTiaConversationApi } from '../../../api/hooks/useTiaConversationApi';
import { CompletionInput, CompletionResponse } from '../../../api/TiaConversationApi';
import { useErrorMessage } from '../../../hooks/useErrorMessage';
import { Message } from '../../../interface/Message';
import CommandDispatcher from '../../../utils/CommandDispatcher';
import { useConversationContext } from './ConversationContext';

export function ChatBotWidgetInput() {
  const { viewer } = useViewerContext<TeiaViewer>();
  const { t } = usePluginTranslation();

  const commandDispatcher = new CommandDispatcher(viewer);

  const { messages, setMessages, conversationId, setConversationId, isLoading, setIsLoading } =
    useConversationContext();
  const { usePostMessage } = useTiaConversationApi(conversationId);
  const { getErrorMessage } = useErrorMessage();

  const onSuccess = (data: CompletionResponse) => {
    setConversationId(messages.length % 20 === 0 ? 0 : data.conversationId);
    addMessage({ role: 'assistant', text: data.message, id: data.messageId });
    data.viewerCommands.forEach((command) => commandDispatcher.executeCommand(command));
  };

  const onError = (error: Error) => {
    addMessage({
      role: 'system',
      text: getErrorMessage(error),
      id: 0
    });
  };

  const { mutateAsync, isPending, isSuccess } = usePostMessage();

  useEffect(() => {
    setIsLoading(isPending);
  }, [isPending]);

  useEffect(() => {}, [isSuccess]);

  useEffect(() => {
    const message: Message = {
      role: 'assistant',
      text: t('chatbot_plugin.messages.welcome_message.text'),
      id: 0
    };
    setMessages([message]);
  }, [setMessages]);

  const [userInput, setUserInput] = useState('');

  const addMessage = (message: Message) => {
    setMessages((previousMessages) => [...previousMessages, message]);
  };

  const onSendMessage = () => {
    if (!viewer.currentSource || 'fileId' in viewer.currentSource) {
      toast.error('You need to have a twin loaded to discuss with Elliot !');
      return;
    }
    if (userInput === '') return;

    addMessage({ text: userInput, role: 'user', id: 0 });

    sendMessage(userInput);
    setUserInput('');
  };

  const sendMessage = async (message: string) => {
    const input: CompletionInput = {
      message,
      projectId: viewer.currentSource!.projectId,
      selectedGuids: Array.from(await new GetSelectedObjectsCommand(viewer).execute())
    };
    mutateAsync({ input }).then(onSuccess).catch(onError);
  };

  return (
    <div className="st-w-full st-flex st-gap-1">
      <ButtonTextInput
        className="st-flex-grow"
        placeholder="Type your question here"
        onValidate={onSendMessage}
        label="Send"
        onClick={onSendMessage}
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        disabled={isLoading}
      />
    </div>
  );
}
