import { SelectionFilter, SelectionFilterType } from '@api/interfaces/SelectionFilter';
import AdvancedSearchIcon from '@resources/icons/AdvancedSearchIcon.svg?react';
import SelectionInfoIcon from '@resources/icons/SelectionInfoIcon.svg?react';
import { Box, MRT_Row, Tooltip } from '@stereograph/teia-system-design';
import { Icon } from '@stereograph/teia-system-design/deprecated';
import { usePluginTranslation } from '@translation';

interface SelectionTypeCellProps {
  row: MRT_Row<SelectionFilter>;
}

export const SelectionTypeCell = (props: SelectionTypeCellProps) => {
  const { row } = props;
  const { t } = usePluginTranslation();
  const isStaticFilter = row.original.type === SelectionFilterType.STATIC;
  const tooltipContent = isStaticFilter
    ? t('selection_plugin.record_widget.selection_list.static_filter.tooltip')
    : t('selection_plugin.record_widget.selection_list.dynamic_filter.tooltip');

  return (
    <Box sx={{ display: 'inline-block' }}>
      <Tooltip title={tooltipContent}>
        <Icon
          icon={{ svgIcon: isStaticFilter ? SelectionInfoIcon : AdvancedSearchIcon }}
          size="lg"
        />
      </Tooltip>
    </Box>
  );
};
