import { TwinObjectApi } from '@stereograph/teia-system-design/apis';
import { useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import type { DefaultError } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import { TeiaQuantityTakeoffColumn } from '../types';
import { teiaQuantityTakeoffColumnListDTO } from '../utils/teiaQuantityTakeoffColumnListDTO';
import { teiaQuantityTakeoffQueryKeys } from '../utils/teiaQuantityTakeoffQueryKeys';

export const useQuantityTakeoffColumns = () => {
  const twin = useTwinSource();
  const queryKeys = teiaQuantityTakeoffQueryKeys.columns;
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;

  const { data, isLoading, error, isFetching } = useQuery<
    Array<TeiaQuantityTakeoffColumn>,
    DefaultError,
    Array<TeiaQuantityTakeoffColumn>
  >({
    queryKey: queryKeys,
    queryFn: async () => {
      const columns = await TwinObjectApi(client).flattenedObjectProperties(twin);
      return teiaQuantityTakeoffColumnListDTO(columns);
    },
    placeholderData: [],
    retry: false
  });

  return {
    twinColumns: data ?? ([] as Array<TeiaQuantityTakeoffColumn>),
    isLoading: isLoading || isFetching,
    error
  };
};
