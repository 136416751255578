import { usePluginTranslation } from '@translation';
import React, { useEffect, useMemo, useState } from 'react';
import { ChatMessage } from './ChatMessage';

export interface ChatContext {
  conversationId: string;
  messages: Array<ChatMessage>;
  isWorking: boolean;
  addMessage: (msg: ChatMessage) => void;
  setIsWorking: (value: boolean) => void;
}

export interface ChatProviderProps {
  children: React.ReactNode;
  conversationId: string;
}

const Context = React.createContext<ChatContext | null>(null);

export const ChatContextProvider = (props: ChatProviderProps) => {
  const { children, conversationId } = props;
  const [messages, setMessages] = useState<Array<ChatMessage>>([]);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const { t } = usePluginTranslation();

  useEffect(() => {
    setMessages([
      {
        role: 'assistant',
        text: t('chatbot_plugin.messages.welcome_message.text'),
        id: 'welcome-message-id',
        completionTokens: 0,
        promptTokens: 0,
        tools: [],
        attachments: []
      }
    ]);
  }, [conversationId]);

  const addMessage = (msg: ChatMessage) => {
    setMessages((messages) => {
      const newMessages = Array.from(messages);
      newMessages.push(msg);
      return newMessages;
    });
  };

  const ctx = useMemo(
    () => ({
      conversationId,
      messages,
      isWorking,
      setIsWorking,
      addMessage
    }),
    [addMessage, setIsWorking, isWorking, messages, conversationId]
  );

  return <Context.Provider value={ctx}>{children}</Context.Provider>;
};

export const useChatContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useConversationContext must be used within a ConversationProvider');
  }
  return context;
};
