import { TwinSearchExportInput } from '@api/interfaces/QuantityTakeoff';
import { TwinApi } from '@api/TwinApi';
import { Twin, useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';

export const TwinApiQueryKey = 'Twin';

export const useTwinApi = (twin: Twin) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const api = TwinApi(client);

  const useSearchExport = (input: TwinSearchExportInput, pageNumber?: number, pageSize?: number) =>
    useQuery({
      queryKey: [twin.projectId, TwinApiQueryKey, input],
      queryFn: () => api.searchExport(twin, input, pageNumber, pageSize)
    });

  return {
    useSearchExport
  };
};
