import { __UnusedParams__ } from '@stereograph/teiaviewer';
import { Attachment } from '../api/attachments/Attachment';

export abstract class AttachmentController<TAttachment extends Attachment> {
  /**
   * Indicates whether the attachment should be displayed in the conversation
   * in its custom ReactComponent
   */
  abstract readonly showInConversation: boolean;

  /**
   * The type of Attachment the handler can process
   */
  abstract type: TAttachment['type'];

  /**
   * Performs actions on the given attachment.
   * Called as soon as attachment is received.
   * @param attachment The attachment to perform actions on
   */
  async processAttachment(attachment: TAttachment) {
    __UnusedParams__(attachment);
  }

  View = AttachmentView<TAttachment>;
}

export interface AttachmentViewProps<TAttachement extends Attachment> {
  attachment: TAttachement;
}

const AttachmentView = <TAttachment extends Attachment>(
  props: AttachmentViewProps<TAttachment>
) => {
  __UnusedParams__(props);
  return <></>;
};
