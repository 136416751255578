import { Anomaly } from '@api/interfaces/Anomaly';
import { Box, MRT_Cell, Text } from '@stereograph/teia-system-design';

interface ClampedTextCellProps {
  cell: MRT_Cell<Anomaly, string>;
}

export const ClampedTextCell = (props: ClampedTextCellProps) => {
  const { cell } = props;

  return (
    <Box
      sx={{
        height: '7em',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Text
        sx={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          overflow: 'hidden'
        }}
      >
        {cell.getValue()}
      </Text>
    </Box>
  );
};
