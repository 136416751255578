import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Flow, FlowConfig, TiaFlowApi } from '../TiaFlowApi';

export const FlowsQueryKey = 'Flows';

export const useTiaFlowApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  const api = TiaFlowApi(client);
  const queryClient = useQueryClient();

  const useGetAllFlows = () =>
    useQuery({
      queryKey: [FlowsQueryKey],
      queryFn: () => api.getAllFlows()
    });

  const useConfigJsonSchema = () =>
    useQuery({
      queryKey: [FlowsQueryKey, 'Schema'],
      queryFn: () => api.getConfigJsonSchema()
    });

  const useCreateFlow = () =>
    useMutation({
      mutationFn: async (t: { name: string; config: FlowConfig }) => {
        const result = await api.createFlow(t.name, t.config);
        await queryClient.invalidateQueries({ queryKey: [FlowsQueryKey] });
        return result;
      }
    });

  const useUpdateFlow = () =>
    useMutation({
      mutationFn: async (t: { flow: Flow }) => {
        const result = await api.updateFlow(t.flow);
        await queryClient.invalidateQueries({ queryKey: [FlowsQueryKey] });
        return result;
      }
    });

  const useDeleteFlow = () =>
    useMutation({
      mutationFn: async (t: { flow: Flow }) => {
        const result = await api.deleteFlow(t.flow);
        await queryClient.invalidateQueries({ queryKey: [FlowsQueryKey] });
        return result;
      }
    });

  return { useGetAllFlows, useConfigJsonSchema, useCreateFlow, useUpdateFlow, useDeleteFlow };
};
