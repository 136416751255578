import { ApiClient, ApiDataList } from '@stereograph/teia-system-design/apis';
import { Comment, CommentQueryParams, RequestPostComment } from './interfaces/Comment';

export const CommentApi = (client: ApiClient, projectId: number) => {
  const url = client.URL(`projects/${projectId}/comments`);

  const getAllComments = (
    commentsQueryParams: CommentQueryParams): Promise<ApiDataList<Comment>> => {
    url.searchParams.set('pageSize', String(commentsQueryParams.pageSize));
    url.searchParams.set('pageNumber', String(commentsQueryParams.pageNumber));
    url.searchParams.set('targetId', String(commentsQueryParams.targetId));
    url.searchParams.set('targetType', String(commentsQueryParams.targetType));

    if (commentsQueryParams.parentId) {
      url.searchParams.set('parentId', String(commentsQueryParams.parentId));
    }

    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<ApiDataList<Comment>>(request);
  };

  const postComment = (comment: RequestPostComment) => {
    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(comment)
    });

    return client.sendRequest(request);
  };

  const deleteComment = (commentId: number) => {
    const deleteUrl = new URL(`${url}/${commentId}`);

    const request = new Request(deleteUrl.toString(), {
      method: 'DELETE'
    });

    return client.sendRequest(request);
  };

  const patchComment = (comment: Comment) => {
    const patchUrl = new URL(`${url}/${comment.id}`);

    const request = new Request(patchUrl, {
      method: 'PATCH',
      body: JSON.stringify(comment)
    });
    return client.sendRequest<Comment>(request);
  };

  return {
    getAllComments,
    postComment,
    deleteComment,
    patchComment
  };
};