import { ApiClient } from '@stereograph/teia-system-design/apis';

export interface ToolConfig {
  name: string;
}

export interface AgentConfig extends ToolConfig {
  backstory?: string;
}

export interface FlowAgentConfig extends AgentConfig {
  tools: Array<FlowAgentConfig | ToolConfig>;
}

export type FlowConfig = FlowAgentConfig;

export interface Flow {
  id: string;
  name: string;
  config: FlowConfig;
  editable: boolean;
}

export interface ModelError {
  type: 'string';
  loc: Array<string>;
  msg: string;
  input: string;
  url: string;
}

export interface ModelValidationError {
  detail: Array<ModelError>;
}

export const TiaFlowApi = (client: ApiClient) => ({
  createFlow(name: string, config: FlowConfig) {
    const url = client.URL('flows');
    const data = JSON.stringify({ name, config });

    const request = new Request(url, {
      method: 'POST',
      body: data
    });

    return client.sendRequest<Flow>(request);
  },

  updateFlow(flow: Flow) {
    const url = client.URL(`flows/${flow.id}`);
    const data = JSON.stringify({ name: flow.name, config: flow.config });

    const request = new Request(url, {
      method: 'PATCH',
      body: data
    });

    return client.sendRequest<Flow>(request);
  },

  getAllFlows() {
    const url = client.URL('flows');
    const request = new Request(url, {
      method: 'GET'
    });

    return client.sendRequest<Array<Flow>>(request);
  },

  getConfigJsonSchema() {
    const url = client.URL('flows/schema');
    const request = new Request(url, {
      method: 'GET'
    });
    return client.sendRequest<any>(request);
  },

  deleteFlow(flow: Flow) {
    const url = client.URL(`flows/${flow.id}`);
    const request = new Request(url, {
      method: 'DELETE'
    });
    return client.sendRequest(request);
  }
});
