import { Button, Stack, TextField } from '@stereograph/teia-system-design';
import {
  ModalView,
  ModalViewContent,
  ModalViewFooter
} from '@stereograph/teia-system-design/deprecated';

import { SelectionFilterType } from '@api/interfaces/SelectionFilter';
import { useTwinSource } from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { Controller, SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelectionFiltersApi } from '../api/useSelectionFilterApi';
import { usePartialLoadFormContext } from '../hooks/usePartialLoadFormContext';
import { computeSearch } from '../utils/computeSearch';
import { getModelsByIds } from '../utils/getModelsByIds';
import { getSelectionsByIds } from '../utils/getSelectionsByIds';
import { PartialLoading } from '../widgets/TeiaPartialLoadingWidgetContent';
import { useTeiaPartialLoadingContext } from '../widgets/TeiaPartialLoadingWidgetProvider';

export const SaveLoadAsSelection = () => {
  const { selections, models } = useTeiaPartialLoadingContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = usePluginTranslation();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const twin = useTwinSource();
  if (!twin) return null;

  const {
    usePostSelectionFilter: { mutate, isPending }
  } = useSelectionFiltersApi(twin.projectId);

  const { control, reset, handleSubmit, watch } = usePartialLoadFormContext();
  const nameValue = watch('name')?.trim().length === 0;
  const selectedModels = watch('models');
  const selectedSelectionFilters = watch('selections');
  const canSave = selectedModels.length > 0 || selectedSelectionFilters.length > 0;
  const onSubmit: SubmitHandler<PartialLoading> = (data) => {
    const selectionsToLoad = getSelectionsByIds(data.selections, selections);
    const modelsToLoad = getModelsByIds(data.models, models);
    const search = computeSearch(selectionsToLoad, modelsToLoad);

    mutate(
      {
        selectionFilter: {
          name: data.name ?? '',
          filter: search,
          type: SelectionFilterType.DYNAMIC,
          isPublic: false
        }
      },
      {
        onSuccess: () => {
          toast.success(t('partial_loading_plugin.form.toast.success.text'));
          reset();
          closeModal();
        },
        onError: () => {
          toast.error(t('partial_loading_plugin.form.toast.error.text'));
        }
      }
    );
  };

  return (
    <>
      <Button
        type="button"
        variant={'outlined'}
        color="primary"
        onClick={openModal}
        disabled={!canSave}
      >
        {t('partial_loading_plugin.form.save_as_selection.label')}
      </Button>
      {createPortal(
        <ModalView
          id="save-load-as-selection-modal"
          isFooter={true}
          variant="white"
          size="sm"
          show={isModalOpen}
          onClose={closeModal}
          title={t('partial_loading_plugin.form.modal.title.text')}
        >
          <ModalViewContent>
            <div>
              <Controller
                render={({ field: { value, onChange } }) => (
                  <TextField
                    label={t('partial_loading_plugin.form.modal.name.label')}
                    required
                    placeholder={t('partial_loading_plugin.form.modal.name.placeholder')}
                    sx={{ width: '100%' }}
                    name={'selection-name'}
                    onChange={onChange}
                    value={value}
                  />
                )}
                control={control}
                name={'name'}
              />
            </div>
            <ModalViewFooter>
              <Stack direction={'row'} spacing={4} sx={{ width: '100%' }} justifyContent={'center'}>
                <Button variant={'text'} color="info" type="button" onClick={closeModal}>
                  {t('partial_loading_plugin.form.modal.button_cancel.label')}
                </Button>
                <Button
                  type="submit"
                  disabled={nameValue || isPending}
                  isLoading={isPending}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('partial_loading_plugin.form.modal.button_save.label')}
                </Button>
              </Stack>
            </ModalViewFooter>
          </ModalViewContent>
        </ModalView>,
        document.getElementById('app') as Element
      )}
    </>
  );
};
