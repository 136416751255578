import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useMutation } from '@tanstack/react-query';
import { CompletionInput, MessageFeedback, TiaConversationApi } from '../TiaConversationApi';

export const useTiaConversationApi = (conversationId: number) => {
  const { plugin } = useViewerPlugin('chatBotLegacy');
  const client = plugin.elliotAPIClient;
  const api = TiaConversationApi(client);

  const usePostFeedback = () =>
    useMutation({
      mutationFn: async (t: { messageId: number; feedback: MessageFeedback }) => {
        return api.postFeedback(conversationId, t.messageId, t.feedback);
      }
    });

  const usePostMessage = () =>
    useMutation({
      mutationFn: async (t: { input: CompletionInput }) => {
        return api.postMessage(conversationId, t.input);
      }
    });

  return { usePostFeedback, usePostMessage };
};
