import { Flow } from '../../api/TiaFlowApi';
import { FlowConfigContextProvider, useFlowConfigContext } from './FlowConfigContext';
import { FlowEditorView } from './FlowEditorView';
import { FlowListView } from './FlowListView';

export const ConfigTabView = () => {
  return (
    <FlowConfigContextProvider>
      <FlowConfigViewRouter />
    </FlowConfigContextProvider>
  );
};

const FlowConfigViewRouter = () => {
  const { mode, flow } = useFlowConfigContext();

  if (mode == 'ListFlows') {
    return <FlowListView />;
  } else if (mode == 'CreateFlow') {
    return <FlowEditorView mode="create" flow={createEmptyFlow()} />;
  } else if (mode == 'UpdateFlow' && flow) {
    return <FlowEditorView mode="update" flow={flow} />;
  }
  return <>No route</>;
};

const createEmptyFlow = () => {
  const flow: Flow = {
    id: 'no-id',
    name: '',
    editable: true,
    config: {
      name: 'user_agent',
      tools: []
    }
  };
  return flow;
};
