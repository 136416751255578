import { AnomalyType } from '@api/interfaces/Anomaly';
import { usePluginTranslation } from '@translation';

export const useAnomalyTypes = (): Record<AnomalyType, string> => {
  const { t } = usePluginTranslation();

  const typeOptions: Record<AnomalyType, string> = {
    [AnomalyType.Clash]: t('anomaly_plugin.widget.form.type.clash.text'),
    [AnomalyType.Inquiry]: t('anomaly_plugin.widget.form.type.inquiry.text'),
    [AnomalyType.Issue]: t('anomaly_plugin.widget.form.type.issue.text'),
    [AnomalyType.Fault]: t('anomaly_plugin.widget.form.type.fault.text'),
    [AnomalyType.Remark]: t('anomaly_plugin.widget.form.type.remark.text'),
    [AnomalyType.Request]: t('anomaly_plugin.widget.form.type.request.text'),
    [AnomalyType.Unknown]: t('anomaly_plugin.widget.form.type.unknown.text')
  };

  return typeOptions;
};

