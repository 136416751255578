import { useViewerPlugin } from '@stereograph/teiaviewer';
import { useQuery } from '@tanstack/react-query';
import { TiaQuotaApi } from '../TiaQuotaApi';

export const QuotaQueryKey = 'Quota';

export const useTiaQuotaApi = () => {
  const { plugin } = useViewerPlugin('chatbot');
  const client = plugin.elliotAPIClient;
  const api = TiaQuotaApi(client);

  const useGetQuota = () =>
    useQuery({
      queryKey: [QuotaQueryKey],
      queryFn: () => api.getQuota()
    });

  return { useGetQuota };
};
