import { TeiaSearchGroup } from '@stereograph/teia-system-design/apis';

export const uuidsToAdvancedSearch = (uuids: Iterable<string>): TeiaSearchGroup => {
  return {
    condition: 'And',
    rules: [
      {
        condition: 'Or',
        rules: Array.from(uuids).map((uuid) => ({
          type: 'Guid',
          operator: 'EqualTo',
          value: uuid
        }))
      }
    ]
  };
};
