import { useQuery } from '@tanstack/react-query';
import { OpenDataApi } from '@api/OpenDataApi';
import { TwinSource, useTeiaViewerContext, useTwinSource } from '@stereograph/teiaviewer';
import { ApiClient } from '@stereograph/teia-system-design/apis';
import { openDataQueryKeys } from '../openData.query-keys';

const fetchAllOpenDataKeys = async (client: ApiClient, twin: TwinSource, accumulatedKeys?: Array<string>, afterKey?: string): Promise<Array<string>> => {
  const response = await OpenDataApi(client).getOpenDataKeys(twin.projectId, twin.type, afterKey);
  const { keys, afterKey: newAfterKey } = response;
  const allKeys = accumulatedKeys ? [...accumulatedKeys, ...keys] : keys;
  if (newAfterKey) {
    return fetchAllOpenDataKeys(client, twin, allKeys, newAfterKey);
  }
  return allKeys;
};

export const useOpenDataPropertyKeys = () => {
  const { viewer } = useTeiaViewerContext();
  const twin = useTwinSource();
  const client = viewer.twinApiClient;
  const { data: openDataPropertyKeys, isLoading } = useQuery({
    queryKey: openDataQueryKeys.propertyKeys(twin.projectId, twin.type),
    queryFn: async () => {
      const keys = await fetchAllOpenDataKeys(client, twin);
      return keys.sort((a, b) => a.localeCompare(b));
    }
  });
  return {
    openDataPropertyKeys,
    isLoading
  };
};