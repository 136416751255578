import { Anomaly, AnomalyHistoryQueryParams, OrderDirection } from '@api/interfaces/Anomaly';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Text
} from '@stereograph/teia-system-design';
import { usePluginTranslation } from '@translation';
import { useState } from 'react';
import { useAnomalyApi } from '../../api/useAnomalyApi';
import { SortHistoriesButton } from './SortHistoriesButton';
import { TeiaAnomalyHistoryItem } from './TeiaAnomalyHistoryItem';

interface TeiaAnomalyHistoryProps {
  anomaly: Anomaly;
}

export const TeiaAnomalyHistory = (props: TeiaAnomalyHistoryProps) => {
  const { anomaly } = props;
  const { t } = usePluginTranslation();
  const [orderDirection, setOrderDirection] = useState<OrderDirection>('asc');
  const { getAnomalyHistory } = useAnomalyApi(anomaly.projectId);

  const anomalyHistoryQueryParams: AnomalyHistoryQueryParams = {
    pageNumber: 1,
    pageSize: 10,
    orderBy: 'creationDate',
    orderDirection: orderDirection
  };

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = getAnomalyHistory(anomaly.id, anomalyHistoryQueryParams);

  const histories = data?.pages.flatMap((page) => page.data) ?? [];

  return (
    <Box key="anomaly-history-accordion">
      <Accordion
        color="white"
      >
        <AccordionSummary>
          <Stack direction="row" spacing={2} alignItems="center">
            <Text variant="lead">{t('anomaly_plugin.widget.history.title')}</Text>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack divider={<Divider flexItem />} spacing={2} sx={{ py: 2 }}>
            <SortHistoriesButton
              orderDirection={orderDirection}
              setOrderDirection={setOrderDirection}
            />
            {histories.map((historyEvent) => (
              <TeiaAnomalyHistoryItem
                anomalyHistory={historyEvent}
                key={`historyEvent-${historyEvent.id}`}
              />
            ))}
            {histories.length === 0 && (
              <Text>
                {t('anomaly_plugin.widget.history.no_data.text')}
              </Text>
            )}
            {hasNextPage && (
              <Button
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
              >
                {t('anomaly_plugin.widget.history.load_more.label')}
              </Button>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
