
import { AnomalyApi } from '@api/AnomalyApi';
import {
  ANOMALY_EXTENSIONS,
  AnomalyFileType,
  AnomalyHistoryQueryParams,
  GetAnomaliesQueryParams,
  RequestPostAnomaly
} from '@api/interfaces/Anomaly';
import { TwinType } from '@api/interfaces/Twin';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePluginTranslation } from '@translation';
import toast from 'react-hot-toast';
import { buildAnomalyErrorMessage, formatAnomalyFilename } from '../widgets/utils';

const queryKeys = ['Anomaly'];

export const useAnomalyApi = (projectId: number) => {
  const { t } = usePluginTranslation();
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;
  const queryClient = useQueryClient();

  const getAnomaly = (anomalyId: number) =>
    useQuery({
      queryKey: [...queryKeys, projectId, anomalyId],
      queryFn: () => {
        return AnomalyApi(client).getAnomaly(projectId, anomalyId);
      }
    });

  const getAnomalies = (anomaliesQueryParams: GetAnomaliesQueryParams) =>
    useQuery({
      queryKey: [...queryKeys, projectId, { anomaliesQueryParams }],
      queryFn: () => {
        return AnomalyApi(client).getAnomalies(projectId, anomaliesQueryParams);
      },
      placeholderData: keepPreviousData
    });

  const { mutateAsync: postAnomaly } = useMutation({
    mutationFn: async ({ anomaly }: { anomaly: RequestPostAnomaly }) => {
      await AnomalyApi(client).postAnomaly(projectId, anomaly);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
    }
  });

  const patchAnomaly = (anomalyId: number) =>
    useMutation({
      mutationFn: async ({ anomaly }: { anomaly: RequestPostAnomaly }) => {
        await AnomalyApi(client).patchAnomaly(projectId, anomalyId, anomaly);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      }
    });

  const getAnomalyHistory = (
    anomalyId: number,
    anomalyHistoryQueryParams: AnomalyHistoryQueryParams = {
      pageNumber: 1,
      pageSize: 10,
      orderBy: 'creationDate',
      orderDirection: 'desc',
    }
  ) => useInfiniteQuery({
    queryKey: [...queryKeys, projectId, anomalyId, 'history', anomalyHistoryQueryParams],
    queryFn: ({ pageParam }) =>
      AnomalyApi(client).getAnomalyHistory(projectId, anomalyId, {
        ...anomalyHistoryQueryParams,
        pageNumber: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.totalPages > lastPage.pageNumber) {
        return lastPage.pageNumber + 1;
      }
      return undefined;
    },
    placeholderData: keepPreviousData
  });


  const exportAnomalies = (anomalyIds: Array<number>, urlExportType: AnomalyFileType) => {
    return useQuery({
      queryKey: [...queryKeys, projectId, urlExportType, ...anomalyIds],
      queryFn: async () => {
        const searchExportResponse = await AnomalyApi(client).exportAnomalies(
          projectId,
          anomalyIds,
          urlExportType
        );

        const filename = formatAnomalyFilename(anomalyIds, ANOMALY_EXTENSIONS[urlExportType]);

        const blob = await searchExportResponse.blob();
        return {
          fileName: filename,
          blob: blob
        };
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount: false,
      retry: 0,
      enabled: false
    });
  };

  const { mutateAsync: importBcfAnomaly } = useMutation({
    mutationFn: async (
      { files, twinProjectType }: { files: Array<File>; twinProjectType: TwinType }
    ) =>
      await AnomalyApi(client).importBcfAnomaly(projectId, files, twinProjectType),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      toast.success(t('anomaly_plugin.info_widget.upload.success_text'));
    },
    onError: () => {
      toast.error(t('anomaly_plugin.info_widget.upload.error_text'));
    }
  });

  const { mutateAsync: importCsvAnomaly } = useMutation({
    mutationFn: async ({
      files,
      twinProjectType,
    }: {
      files: Array<File>;
      twinProjectType: TwinType;
    }) =>
      await AnomalyApi(client).importCsvAnomaly(projectId, files, twinProjectType),
    onSuccess: async (response) => {
      queryClient.invalidateQueries({ queryKey: [...queryKeys, projectId] });
      buildAnomalyErrorMessage(response);
    }
  });


  return {
    getAnomaly,
    postAnomaly,
    patchAnomaly,
    getAnomalyHistory,
    getAnomalies,
    importBcfAnomaly,
    importCsvAnomaly,
    exportAnomalies
  };
};
