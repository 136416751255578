import { useAttachmentControllerRegistryContext } from '../../../attachment/AttachmentControllerRegistry';
import { AttachmentMessage } from '../ChatMessage';
import { MessageView } from '../common/MessageView';

export interface AttachmentMessageViewProps {
  message: AttachmentMessage;
}

export const AttachmentMessageViewer = (props: AttachmentMessageViewProps) => {
  const { message } = props;
  const { registry } = useAttachmentControllerRegistryContext();

  const controller = registry.getController(message.attachment.type);

  return (
    <MessageView color="info.light">
      <controller.View attachment={message.attachment} />
    </MessageView>
  );
};
