import { TwinObjectQuantityTakeoffAggregate } from '@stereograph/teia-system-design/apis';
import { ReactNode } from 'react';
import { TeiaQuantityTakeoffColumn } from '../types';
import { TeiaQuantityTakeoffAggregates } from './TeiaQuantityTakeoffAggregates';

interface Cell {
  getValue: () => string | number | TwinObjectQuantityTakeoffAggregate;
}

interface TeiaQuantityTakeoffTableCellProps {
  cell: Cell;
  displayedColumn: TeiaQuantityTakeoffColumn;
  groupBy: TeiaQuantityTakeoffColumn | null;
  renderedCellValue: ReactNode;
}

export const TeiaQuantityTakeoffTableCell = (props: TeiaQuantityTakeoffTableCellProps) => {
  const { cell, displayedColumn, groupBy, renderedCellValue } = props;

  if (groupBy && typeof cell.getValue() === 'object') {
    const cellValue = cell.getValue() as TwinObjectQuantityTakeoffAggregate;
    return <TeiaQuantityTakeoffAggregates aggregates={cellValue} column={displayedColumn.value} />;
  }
  /**
   * Fix if the cell value is an object with a max property,
   * it means that the cell is an aggregate cell out of render loop
   * Maybe a React State loop render issue
   */
  if (
    renderedCellValue !== null &&
    typeof renderedCellValue === 'object' &&
    'max' in renderedCellValue
  ) {
    return '';
  }

  return renderedCellValue;
};
