import { Model } from '@api/interfaces/Model';
import { TeiaSearchRule } from '@stereograph/teia-system-design/apis';

export const formartModelToSearchRule = (model: Model): TeiaSearchRule => {
  return {
    condition: 'Or',
    rules: [
      {
        type: 'Property',
        operator: 'EqualTo',
        property: 'Stereograph',
        name: 'ModelId',
        value: String(model.ifcModelId)
      }
    ]
  };
};
