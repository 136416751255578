import { Twin } from '@api/interfaces/Twin';
import { TwinApi } from '@api/TwinApi';
import {
  AddSpriteCommand,
  AddSpriteCommandOptions,
  CameraFocusSelectedObjectsCommand,
  CameraZoomInCommand,
  CameraZoomOutCommand,
  CenterCameraOnSceneCommand,
  HexColor,
  IsolateObjectCommand,
  SelectObjectsCommand,
  SetObjectsColorCommand,
  SetObjectsOpacityCommand,
  SetObjectsVisibilityCommand,
  SetOptionCommand,
  TeiaViewer
} from '@stereograph/teiaviewer';
import { ColorRepresentation } from 'three';
import { ViewerCommand } from '../interface/ViewerCommand';

export default class CommandDispatcher {
  constructor(private viewer: TeiaViewer) {
  }

  async executeCommand(inputCommand: ViewerCommand) {
    let command: any = {};
    const commandName: string = inputCommand.command;
    const guids: Array<string> = inputCommand.guids;
    const specialParams: Record<string, any> = inputCommand.specialParams;
    if (commandName === 'SetBackgroundColor') {
      command = new SetOptionCommand<HexColor>(
        this.viewer,
        this.viewer.settings.scene.backgroundColor,
        `#${parseInt(specialParams['color'], 16)}`
      );
    } else if (commandName === 'AddSprite') {
      const options: AddSpriteCommandOptions = {
        text: specialParams['text'],
        selectable: true,
        imageUrl: '/resources/images/camera.svg'
      };
      command = new AddSpriteCommand(this.viewer, 0, 0, 0, options);
    } else if (commandName === 'SelectObjects') {
      command = new SelectObjectsCommand(this.viewer, guids);
    } else if (commandName === 'IsolateObjects') {
      command = new IsolateObjectCommand(this.viewer, guids);
    } else if (commandName === 'SetObjectsColor') {
      const color: ColorRepresentation = parseInt(specialParams['color'], 16);
      command = new SetObjectsColorCommand(this.viewer, guids, color);
    } else if (commandName === 'SetObjectsOpacity') {
      const opacity: number = parseFloat(specialParams['opacity']);
      command = new SetObjectsOpacityCommand(this.viewer, guids, opacity);
    } else if (commandName === 'SetObjectsVisibility') {
      const visible = Boolean(specialParams['visibility']);
      command = new SetObjectsVisibilityCommand(this.viewer, guids, visible);
    } else if (commandName === 'CameraZoom') {
      const direction: string = specialParams['direction'];
      switch (direction) {
        case 'in':
          command = new CameraZoomInCommand(this.viewer);
          break;
        case 'out':
          command = new CameraZoomOutCommand(this.viewer);
          break;
        default:
          console.error('Invalid direction: %s', direction);
          return;
      }
    } else if (commandName === 'CameraFocusSelectedObjects') {
      command = new CameraFocusSelectedObjectsCommand(this.viewer);
    } else if (commandName === 'CenterCamera') {
      command = new CenterCameraOnSceneCommand(this.viewer);
    } else if (commandName === 'ExportQto') {
      const twinSource: Twin = {
        projectId: this.viewer.currentSource!.projectId,
        type: 'Draft'
      };
      const client = this.viewer.twinApiClient;
      client.baseUrl = this.viewer.settings.twinApi.baseUrl.value;
      client.token = this.viewer.settings.twinApi.token.value;
      const twinApi = TwinApi(client);
      const filter = {
        columns: specialParams['columns'],
        group: specialParams['group'],
        twinObjectGuids: [...this.viewer.scene.objectSelection.getUuids()],
        fileName: specialParams['name'],
        rule: {}
      };
      // IMPORTANT: Most of this code is reused from the original implementation in the viewer, exporting 'useTwinSearchExport' would simplify this code
      const res = async () => {
        const searchExportResponse = await twinApi.downloadExport(twinSource, filter);
        const headerDisposition = searchExportResponse.headers.get('Content-Disposition');

        if (headerDisposition && headerDisposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(headerDisposition);

          if (matches?.[1]) {
            filter.fileName = matches[1].replace(/['"]/g, '');
          }
        }
        const blob = await searchExportResponse.blob();
        return {
          fileName: filter.fileName,
          blob: blob
        };
      };
      const { fileName, blob } = await res();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);

      // Not implemented yet:

      // case("ResetScene"):
      //   command = new ResetSceneCommand(viewer);
      //   break;
    } else {
      console.error('Command: %s is not handled', commandName);
    }

    console.log('Executing command: %s', commandName);
    command.execute();
  }
}
