import { ApiClient } from '@stereograph/teia-system-design/apis';
import { PatchDataPostRequest } from './interfaces/PatchData';
import { TwinType } from './interfaces/Twin';

export const PatchDataApi = (client: ApiClient) => ({
  postPatchData(projectId: number, twinType: TwinType, patchData: PatchDataPostRequest) {
    const url = client.URL(`projects/${projectId}/patchdatarequests`);
    url.searchParams.set('twinProjectType', twinType);

    const request = new Request(url, {
      method: 'POST',
      body: JSON.stringify(patchData)
    });
    return client.sendRequest(request);
  }
});
