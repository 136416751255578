import { AnomalyScene } from '@api/interfaces/Anomaly';
import { EyeIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@stereograph/teia-system-design/deprecated';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { SetAnomalySceneCommand } from '../command/SetAnomalySceneCommand';

interface LoadAnomalySceneButtonProps {
  anomalyScene: AnomalyScene;
}

export const LoadAnomalySceneButton = (props: LoadAnomalySceneButtonProps) => {
  const { anomalyScene } = props;
  const { viewer } = useTeiaViewerContext();

  const onLoadAnomalyContext = () => {
    const command = new SetAnomalySceneCommand(viewer, anomalyScene);
    command.execute();
  };

  return <IconButton icon={{ heroIcon: EyeIcon }} onClick={onLoadAnomalyContext} />;
};
