import { AnomalyPriority } from '@api/interfaces/Anomaly';
import { usePluginTranslation } from '@translation';

export const useAnomalyPriorities = (): Record<AnomalyPriority, string> => {
  const { t } = usePluginTranslation();

  const priorityOptions: Record<AnomalyPriority, string> = {
    [AnomalyPriority.Critical]: t('anomaly_plugin.widget.form.priority.critical.text'),
    [AnomalyPriority.Major]: t('anomaly_plugin.widget.form.priority.major.text'),
    [AnomalyPriority.Normal]: t('anomaly_plugin.widget.form.priority.normal.text'),
    [AnomalyPriority.Minor]: t('anomaly_plugin.widget.form.priority.minor.text'),
    [AnomalyPriority.OnHold]: t('anomaly_plugin.widget.form.priority.on_hold.text'),
    [AnomalyPriority.Unknown]: t('anomaly_plugin.widget.form.priority.unknown.text')
  };

  return priorityOptions;
};