import React, { useState } from 'react';
import { Message } from '../../../interface/Message';

export interface ConversationContext {
  conversationId: number;
  messages: Array<Message>;
  isLoading: boolean;
  setConversationId: React.Dispatch<React.SetStateAction<number>>;
  setMessages: React.Dispatch<React.SetStateAction<Array<Message>>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ConversationProviderProps {
  children: React.ReactNode;
}

const Context = React.createContext<ConversationContext | null>(null);

export const ConversationProvider = (props: ConversationProviderProps) => {
  const { children } = props;

  const [conversationId, setConversationId] = useState<number>(0);
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Context.Provider
      value={{ conversationId, messages, isLoading, setConversationId, setMessages, setIsLoading }}
    >
      {children}
    </Context.Provider>
  );
};

export const useConversationContext = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error('useConversationContext must be used within a ConversationProvider');
  }
  return context;
};
