import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button, IconButton, Stack, Tooltip } from '@stereograph/teia-system-design';
import { parse } from 'csv-parse/browser/esm/sync';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTiaPrePromptApi } from '../../../../api/hooks/useTiaPrePromptApi';
import { BasePrompts, PrePromptEnum } from './TemplateTabUtils/BasePrompts';
import { CSVExamples } from './TemplateTabUtils/CSVExamples';
import { keyValueRecordToString } from './TemplateTabUtils/keyValueRecordToSting';

export function PrePromptTemplatesTabTabContent() {
  const { useAddPrePrompt } = useTiaPrePromptApi();
  const { mutateAsync: addPrePrompt, isPending: isAdding } = useAddPrePrompt();

  const [prePromptEnum, setPrePromptEnum] = useState<PrePromptEnum>(PrePromptEnum.NONE);

  const [examplePrePromptEnum, setExamplePrePromptEnum] = useState<PrePromptEnum>(
    PrePromptEnum.NONE
  );

  const parseText = (text: string) => {
    const records: Array<Array<string>> = parse(text, {
      skip_empty_lines: true,
      trim: true
    });

    if (
      !records.every((record) => record.length === 2) &&
      !(prePromptEnum in [PrePromptEnum.ABREVIATION, PrePromptEnum.DATA_SHORTCUT])
    ) {
      toast.error(
        'All lines must have exactly two columns for Abreviation and Data Shortcut prompts'
      );
      return;
    }

    const promptSuffix: string = BasePrompts.getPrePromptTemplate(prePromptEnum);
    let prePrompt = '';
    switch (prePromptEnum) {
      case PrePromptEnum.ABREVIATION:
      case PrePromptEnum.DATA_SHORTCUT:
        prePrompt = `${promptSuffix}\n${keyValueRecordToString(records)}`;
        break;
      default:
        break;
    }

    addPrePrompt(
      { text: prePrompt },
      {
        onError: (error: Error) => {
          toast.error('Failed to create pre-prompt');
          console.error(error);
        },
        onSuccess: () => {
          toast.success('Pre-prompt created');
        }
      }
    );
  };

  const createPrompt = () => {
    const fileInput = document.createElement('input');
    if (!fileInput) {
      return;
    }
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.csv');
    fileInput.onchange = () => {
      if (!fileInput.files || fileInput.files.length !== 1) {
        toast.error('Please select a (single) file');
        return;
      }
      const file = fileInput.files[0];
      file?.text().then(parseText);
    };
    fileInput.click();
  };

  useEffect(() => {
    if (prePromptEnum === PrePromptEnum.NONE) {
      return;
    }
    createPrompt();
    setPrePromptEnum(PrePromptEnum.NONE);
  }, [prePromptEnum]);

  useEffect(() => {
    if (examplePrePromptEnum === PrePromptEnum.NONE) {
      return;
    }
    const url = URL.createObjectURL(
      new Blob([CSVExamples.getContent(examplePrePromptEnum)], {
        type: 'text/csv'
      })
    );
    const a = document.createElement('a');
    a.href = url;
    a.download = CSVExamples.getTitle(examplePrePromptEnum);
    a.click();
    URL.revokeObjectURL(url);
    setExamplePrePromptEnum(PrePromptEnum.NONE);
  }, [examplePrePromptEnum]);

  return (
    <div className="st-gap-5 st-flex st-flex-col">
      <p className="st-font-semibold">Pre-Prompts Templates:</p>
      <Stack direction={'row'}>
        <Button
          color="primary"
          onClick={() => {
            setPrePromptEnum(PrePromptEnum.ABREVIATION);
          }}
          disabled={isAdding}
          sx={{ flexGrow: 1 }}
        >
          Generate Abreviation Prompt
        </Button>
        <Tooltip title="Download Example as CSV">
          <IconButton
            aria-label="Download Example as CSV"
            sx={{ width: 40, height: 40 }}
            iconOnly
            onClick={() => {
              setExamplePrePromptEnum(PrePromptEnum.ABREVIATION);
            }}
          >
            <InformationCircleIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack direction={'row'}>
        <Button
          color="primary"
          onClick={() => {
            setPrePromptEnum(PrePromptEnum.DATA_SHORTCUT);
          }}
          disabled={isAdding}
          sx={{ flexGrow: 1 }}
        >
          Generate Data Shortcut Prompt
        </Button>
        <Tooltip title="Download Example as CSV">
          <IconButton
            aria-label="Download Example as CSV"
            sx={{ width: 40, height: 40 }}
            iconOnly
            onClick={() => {
              setExamplePrePromptEnum(PrePromptEnum.DATA_SHORTCUT);
            }}
          >
            <InformationCircleIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </div>
  );
}
