import {
  fetchAllPages,
  TeiaSearchFilter,
  TeiaSearchRule,
  TwinObject
} from '@stereograph/teia-system-design/apis';
import {
  AddClippingPlaneCommand,
  CameraFocusObjectsCommand,
  ClippingPlaneType,
  IsolateObjectCommand,
  OpenWidgetCommand,
  ResetSceneCommand,
  SelectObjectsCommand,
  SetObjectsColorCommand,
  SetObjectsVisibilityCommand,
  SetObjectsXRayStatusCommand,
  TeiaViewer,
  TeiaViewerSourceType,
  Twin,
  TwinObjectApi
} from '@stereograph/teiaviewer';
import { ViewerCommandAttachment } from '../../api/attachments/Attachment';
import { ViewerCommand } from '../../api/attachments/ViewerCommand';
import { AttachmentController } from '../AttachmentController';

export class ViewerCommandAttachmentController extends AttachmentController<ViewerCommandAttachment> {
  readonly type = 'viewer_command';
  readonly showInConversation = false;
  private readonly _viewer: TeiaViewer;

  constructor(viewer: TeiaViewer) {
    super();
    this._viewer = viewer;
  }

  override async processAttachment(attachment: ViewerCommandAttachment) {
    executeCommand(this._viewer, attachment.command);
  }
}

const ClippingPlaneTypeByString = {
  X: ClippingPlaneType.x,
  Y: ClippingPlaneType.y,
  Z: ClippingPlaneType.z
};

async function executeCommand(viewer: TeiaViewer, input_cmd: ViewerCommand) {
  const twin = viewer.currentSource;
  if (!twin || twin.sourceType !== TeiaViewerSourceType.Twin) {
    console.error('Cannot execute viewer commands on');
    return;
  }

  if (input_cmd.type === 'color_objects') {
    const { color, filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new SetObjectsColorCommand(viewer, uuids, color);
    await cmd.execute();
  } else if (input_cmd.type === 'select_objects') {
    const { filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new SelectObjectsCommand(viewer, uuids);
    await cmd.execute();
  } else if (input_cmd.type === 'set_objects_visibility') {
    const { visibility, filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new SetObjectsVisibilityCommand(viewer, uuids, visibility);
    await cmd.execute();
  } else if (input_cmd.type === 'add_clipping_plane') {
    const { clippingPlaneType } = input_cmd;
    const cmd = new AddClippingPlaneCommand(viewer, ClippingPlaneTypeByString[clippingPlaneType]);
    await cmd.execute();
  } else if (input_cmd.type === 'focus_objects') {
    const { radiusMultiplier, filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new CameraFocusObjectsCommand(viewer, uuids, radiusMultiplier);
    await cmd.execute();
  } else if (input_cmd.type === 'isolate_objects') {
    const { filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new IsolateObjectCommand(viewer, uuids);
    await cmd.execute();
  } else if (input_cmd.type === 'open_widget_infobox') {
    const { filter } = input_cmd;
    const uuid_gen = uuid_generator(await search_objects(viewer, twin, filter));
    const uuids = Array.from(uuid_gen);
    const cmd = new OpenWidgetCommand(viewer, 'Infobox', { uuids: uuids, locked: false });
    await cmd.execute();
  } else if (input_cmd.type === 'reset_scene') {
    const cmd = new ResetSceneCommand(viewer);
    await cmd.execute();
  } else if (input_cmd.type === 'set_objects_x_ray_mode') {
    const { xRay, filter } = input_cmd;
    const uuids = uuid_generator(await search_objects(viewer, twin, filter));
    const cmd = new SetObjectsXRayStatusCommand(viewer, uuids, xRay);
    await cmd.execute();
  } else if (input_cmd.type === 'open_qto_widget') {
    const { filter } = input_cmd;
    const cmd = new OpenWidgetCommand(viewer, 'TeiaQuantityTakeOff', {
      searchRules: { condition: 'Or', rules: [filter as TeiaSearchRule] }
    });
    await cmd.execute();
  } else if (input_cmd.type === 'undo') {
    await viewer.commandHistory.undoLastCommand();
  } else if (input_cmd.type === 'redo') {
    await viewer.commandHistory.redoLastCommand();
  }
}

async function search_objects(viewer: TeiaViewer, twin: Twin, filter: TeiaSearchFilter) {
  const api = TwinObjectApi(viewer.twinApiClient);
  return await fetchAllPages((pageNumber, pageSize) =>
    api.searchTwinObjects(twin, pageNumber, pageSize, filter)
  );
}

function* uuid_generator(objects: Array<TwinObject>) {
  for (const obj of objects) {
    yield obj.guid;
  }
}
