import { AnomalyStatus } from '@api/interfaces/Anomaly';
import { usePluginTranslation } from '@translation';

export const useAnomalyStatus = (): Record<AnomalyStatus, string> => {
  const { t } = usePluginTranslation();

  const statusOptions: Record<AnomalyStatus, string> = {
    [AnomalyStatus.Active]: t('anomaly_plugin.widget.form.status.active.text'),
    [AnomalyStatus.InProgress]: t('anomaly_plugin.widget.form.status.in_progress.text'),
    [AnomalyStatus.Resolved]: t('anomaly_plugin.widget.form.status.resolved.text'),
    [AnomalyStatus.Closed]: t('anomaly_plugin.widget.form.status.closed.text'),
    [AnomalyStatus.ReOpened]: t('anomaly_plugin.widget.form.status.reopened.text'),
    [AnomalyStatus.Unknown]: t('anomaly_plugin.widget.form.status.unknown.text')
  };

  return statusOptions;
};
