import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { CommentApi } from '../CommentApi';
import { CommentQueryParams } from '../interfaces/Comment';
import { commentQueryKeys } from '../queryKeys/comment.query-keys';

export const useGetComments = (projectId: number, commentQueryParams: CommentQueryParams) => {
  const { viewer } = useTeiaViewerContext();
  const client = viewer.twinApiClient;

  const getComments = useInfiniteQuery({
    queryKey: commentQueryKeys.getAll(projectId, commentQueryParams),
    queryFn: ({ pageParam }) => CommentApi(client, projectId).getAllComments({
      ...commentQueryParams,
      pageNumber: pageParam,
    }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.totalPages > lastPage.pageNumber) {
        return lastPage.pageNumber + 1;
      }
      return undefined;
    },
    gcTime: 0,
    staleTime: 0,
  });

  return {
    getComments,
  };
};
