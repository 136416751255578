import { CommentQueryParams } from '@api/interfaces/Comment';
import { useGetComments } from '../../../api/hooks/useGetComments';

export const useGetAnomalyComments = (projectId: number) => {
  const getAnomalyComments = (commonQueryParams: CommentQueryParams) => {
    const commentQueryParams: CommentQueryParams = {
      pageNumber: commonQueryParams.pageNumber,
      pageSize: commonQueryParams.pageSize,
      targetId: commonQueryParams.targetId,
      targetType: 'Anomaly',
      parentId: commonQueryParams.parentId,
    };

    const { getComments } = useGetComments(projectId, commentQueryParams);

    if (!getComments.data) {
      return null;
    }

    return getComments.data.pages.flatMap((page) => page.data);
  };

  return {
    getAnomalyComments
  };
};
