import { TeiaSearchGroup } from '@stereograph/teia-system-design/apis';
import {
  StandardWidget,
  StandardWidgetContextProviderProps,
  StandardWidgetOptions,
  TeiaViewer,
  TeiaViewerSourceType,
  useTeiaSource,
  WidgetFactoryFunction
} from '@stereograph/teiaviewer';
import { usePluginTranslation } from '@translation';
import { TeiaQuantityTakeoffWidgetProvider } from '../components/TeiaQuantityTakeoffProvider';
import { TeiaQuantityTakeoffWidgetContent } from './TeiaQuantityTakeoffWidgetContent';
import { TeiaQuantityTakeoffWidgetFooter } from './TeiaQuantityTakeoffWidgetFooter';

declare global {
  interface ViewerWidgetRegistry {
    TeiaQuantityTakeOff: WidgetFactoryFunction<
      TeiaQuantityTakeoffWidget,
      TeiaQuantityTakeoffWidgetOptions
    >;
  }
}

export interface TeiaQuantityTakeoffWidgetOptions extends StandardWidgetOptions {
  searchRules: TeiaSearchGroup;
}

export class TeiaQuantityTakeoffWidget extends StandardWidget {
  readonly type = 'TeiaQuantityTakeOff';
  searchRules: TeiaSearchGroup;

  constructor(viewer: TeiaViewer, options: TeiaQuantityTakeoffWidgetOptions) {
    const { searchRules, ...otherOptions } = options;

    super(viewer, {
      color: 'info',
      minWidth: 400,
      minHeight: 400,
      ...otherOptions
    });

    this.searchRules = searchRules;
  }

  override Header = () => {
    const { t } = usePluginTranslation();
    return t('quantity_takeoff_plugin.title');
  };

  override Content = () => {
    return <TeiaQuantityTakeoffWidgetContent />;
  };

  override Footer = () => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) return null;

    return <TeiaQuantityTakeoffWidgetFooter />;
  };
  override ContextProvider = (props: StandardWidgetContextProviderProps) => {
    const twin = useTeiaSource(TeiaViewerSourceType.Twin);
    if (!twin) return null;

    return <TeiaQuantityTakeoffWidgetProvider {...props} searchRules={this.searchRules} />;
  };
}
