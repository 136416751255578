import { WrenchScrewdriverIcon } from '@heroicons/react/24/outline';
import {
  OpenWidgetCommand,
  TeiaViewer,
  ToolbarItem,
  useViewerContext
} from '@stereograph/teiaviewer';

export const ElliotConfigToolbarItem = () => {
  const { viewer } = useViewerContext<TeiaViewer>();

  const onClick = () => {
    const cmd = new OpenWidgetCommand(viewer, 'ElliotConfigWidget');
    cmd.execute();
  };

  return (
    <ToolbarItem
      icon={{ heroIcon: WrenchScrewdriverIcon }}
      label="Configure Elliot"
      onClick={onClick}
    />
  );
};
