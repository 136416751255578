import { ThumbDownAlt, ThumbDownOffAlt, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import { IconButton, Tooltip } from '@stereograph/teia-system-design';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTiaConversationApi } from '../../api/hooks/useTiaConversationApi';
import { MessageFeedback } from '../../api/TiaConversationApi';
import { useErrorMessage } from '../../hooks/useErrorMessage';
import { Message } from '../../interface/Message';
import TypewriterText from '../typing/TypewriterText';

interface AssistantMessageProps {
  index: number;
  message: Message;
  conversationId: number;
  isLastMessage: boolean;
}

export default function AssistantMessage(props: AssistantMessageProps) {
  const { index, message, conversationId, isLastMessage } = props;
  const [feedback, setFeedback] = useState<MessageFeedback | null>(null);
  const messageId = message.id;
  const { usePostFeedback } = useTiaConversationApi(conversationId);
  const { mutateAsync, isPending } = usePostFeedback();
  const { getErrorMessage } = useErrorMessage();

  useEffect(() => {
    if (!feedback) {
      return;
    }
    mutateAsync(
      { messageId, feedback },
      {
        onError: (error) => {
          setFeedback(null);
          toast.error(getErrorMessage(error));
        }
      }
    );
  }, [feedback]);

  return (
    <>
      <div
        key={index}
        className="st-w-auto st-p-1 st-rounded-md st-text-sm st-font-normal st-mr-auto st-bg-orange-200 st-text-left"
        style={{ maxWidth: '85%' }}
      >
        <p className="st-flex st-h-5 st-font-thin st-pr-2">
          <span className="st-mr-1 st-text-base">🤖</span>
          Elliot
        </p>
        {isLastMessage ? <TypewriterText message={message.text} /> : <p>{message.text}</p>}
      </div>
      {index !== 0 && (
        <div className="st-flex st-float-right">
          {
            <Tooltip title="Bonne réponse">
              <IconButton
                iconOnly
                aria-label="Bonne réponse"
                color={feedback === 'negative' || isPending ? 'info' : 'primary'}
                onClick={() => {
                  setFeedback('positive');
                }}
              >
                {feedback === 'positive' ? <ThumbUpAlt /> : <ThumbUpOffAlt />}
              </IconButton>
            </Tooltip>
          }
          {
            <Tooltip title="Mauvaise réponse">
              <IconButton
                iconOnly
                aria-label="Mauvaise réponse"
                color={feedback === 'positive' || isPending ? 'info' : 'error'}
                onClick={() => {
                  setFeedback('negative');
                }}
              >
                {feedback === 'negative' ? <ThumbDownAlt /> : <ThumbDownOffAlt />}
              </IconButton>
            </Tooltip>
          }
        </div>
      )}
    </>
  );
}
