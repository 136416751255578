import { BookmarkIcon, ClipboardDocumentListIcon } from '@heroicons/react/24/outline';
import { InfoBoxTab } from '@stereograph/teiaviewer';
import { PrePromptConfigTabTabContent } from './tabs/PrePromptsConfigTab';
import { PrePromptTemplatesTabTabContent } from './tabs/PrePromptTemplatesTab';

function PrePromptConfigTab(options: Partial<InfoBoxTab> = {}) {
  return new InfoBoxTab(
    options.icon ?? { heroIcon: BookmarkIcon },
    options.Title ?? (() => <></>),
    () => <PrePromptConfigTabTabContent />,
    options.Tooltip ?? 'Pre-Prompt Config'
  );
}

function PrePromptTemplatesTab(options: Partial<InfoBoxTab> = {}) {
  return new InfoBoxTab(
    options.icon ?? { heroIcon: ClipboardDocumentListIcon },
    options.Title ?? (() => <></>),
    () => <PrePromptTemplatesTabTabContent />,
    options.Tooltip ?? 'Pre-Prompt Templates'
  );
}

export const ElliotConfigTabs = {
  PrePromptConfig: PrePromptConfigTab,
  PrePromptTemplates: PrePromptTemplatesTab
};
