import { Box } from '@stereograph/teia-system-design';
import { useTeiaViewerContext } from '@stereograph/teiaviewer';
import { useEffect, useRef } from 'react';
import { useAttachmentControllerRegistryContext } from '../../attachment/AttachmentControllerRegistry';
import { QTORequestAttachmentController } from '../../attachment/controllers/QTORequestAttachmentController';
import { ViewerCommandAttachmentController } from '../../attachment/controllers/ViewerCommandAttachmentController';
import { useChatContext } from '../../components/chat/ChatContext';
import { ChatInputField } from '../../components/chat/ChatInputField';
import { AssistantMessageView } from '../../components/chat/messages/AssistantMessageView';
import { AttachmentMessageViewer } from '../../components/chat/messages/AttachmentMessageView';
import { SystemMessageView } from '../../components/chat/messages/SystemMessageView';
import { TypingMessageView } from '../../components/chat/messages/TypingMessageView';
import { UserMessageView } from '../../components/chat/messages/UserMessageView';

export const ChatView = () => {
  const { registry } = useAttachmentControllerRegistryContext();
  const { viewer } = useTeiaViewerContext();

  useEffect(() => {
    registry.addController(new QTORequestAttachmentController());
    registry.addController(new ViewerCommandAttachmentController(viewer));
    return () => {
      registry.clear();
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flexGrow: 1
      }}
    >
      <MessagesList />
      <ChatInputField />
    </Box>
  );
};

const MessagesList = () => {
  const { messages, conversationId, isWorking } = useChatContext();
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chat = messageContainerRef.current;
    if (chat) {
      const observer = new MutationObserver(() => {
        chat.scrollTop = chat.scrollHeight;
      });

      observer.observe(chat, {
        childList: true,
        subtree: true,
        characterData: true
      });

      return () => observer.disconnect();
    }
    return () => {};
  }, []);

  return (
    <div
      ref={messageContainerRef}
      style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        marginTop: 2,
        marginBottom: 2,
        overflowY: 'scroll'
      }}
    >
      {messages.map((message, index) => {
        switch (message.role) {
          case 'assistant':
            return (
              <AssistantMessageView
                key={message.id}
                message={message}
                conversationId={conversationId}
                isLastMessage={index === messages.length - 1}
                isFirstMessage={index === 0}
              />
            );
          case 'user':
            return <UserMessageView key={message.id} message={message} />;
          case 'system':
            return <SystemMessageView key={message.id} message={message} />;
          case 'attachment':
            return <AttachmentMessageViewer key={message.id} message={message} />;
          default:
            return <></>;
        }
      })}
      {isWorking && <TypingMessageView key={'typing-message'} />}
    </div>
  );
};
